import React from 'react';
import {
  formatMoney,
  T_PlanSelector,
  links,
  useJSONConfig,
  useLockBodyScrollOnValue,
  useSplitIO,
  SPLITIO_KEY,
  useNewAcquisitionOrderStore,
} from '@sky-tv-group/shared';
import { Aspect, Button, FancyPrice, BBExploreModal, useModal } from '@sky-tv-group/components';
import { focusOnAddressSelector } from '../../helper';
import { configService, konakartService } from '../../services';

interface PlanProps {
  plan: T_PlanSelector;
  onPlanSelect: (index: string) => void;
  isStarterInCart: boolean | undefined;
  canPurchaseBroadband: boolean | undefined;
  isPlanSelected: boolean;
}

const Plan = ({ plan, onPlanSelect, isStarterInCart, canPurchaseBroadband, isPlanSelected }: PlanProps) => {
  const { isUpdatingPlan } = useNewAcquisitionOrderStore();
  const selectPlan = () => onPlanSelect(plan.product?.sku!);
  const [showBBOneMonthOnUs] = useSplitIO(SPLITIO_KEY.SKYWEB_BB_ONE_MONTH_ON_US_ON);
  const [BBCreditOfferFlag] = useSplitIO(SPLITIO_KEY.SKYWEB_BB_CREDIT_OFFER);

  const addBtnLabel = canPurchaseBroadband ? (isPlanSelected ? 'Added' : 'Add') : 'Get Started';
  const addBtnIcon = isUpdatingPlan ? 'loading' : canPurchaseBroadband ? (isPlanSelected ? 'check' : 'add') : undefined;
  const addBtnAction = canPurchaseBroadband ? selectPlan : focusOnAddressSelector;
  const { showModal, toggleModal } = useModal();
  const config = useJSONConfig(configService);
  const savingsWithSkyTV = Number(plan.price.nonStarterPrice) - Number(plan.price.fullPrice);
  const nonStarterPrice = Number(plan.product?.price0);
  const fibreSpeed = plan.product?.custom8?.replace('Up to ', '');
  useLockBodyScrollOnValue(showModal);

  return (
    <>
      {/* Desktop */}
      <div
        className="card flex-1 flex-row overflow-hidden rounded-lg h-330px hidden xl:flex text-navy"
        data-testid={`bb-plan-${plan.index}`}>
        <div className=" relative " style={{ width: 296 }}>
          {showBBOneMonthOnUs && (
            <div
              className="absolute w-100px h-100px"
              style={{
                zIndex: 1,
                right: '10px',
                top: '10px',
              }}>
              <img className="w-full" src={`https://static.sky.co.nz/sky/icons/1monthonus.svg`} alt="" />
            </div>
          )}
          {BBCreditOfferFlag && (
            <div
              className="absolute w-100px h-100px"
              style={{
                zIndex: 1,
                right: '10px',
                top: '10px',
              }}></div>
          )}
          <Aspect ratio={4 / 3}>
            <img src={plan?.imgSrc} className="w-full h-full" alt=""></img>
          </Aspect>
        </div>
        <div
          className={`flex-1 2xl:p-5 xl:p-2 flex flex-col justify-between rounded-lg rounded-l-none border-l-0 ${
            !isPlanSelected ? 'border-2' : 'border-2 border-pink'
          }`}>
          <h3 className="sky-h2-black">{plan.header.title}</h3>
          <ul className="flex flex-row flex-wrap gap-5 sky-h7-reg">
            <li className="flex items-center">
              <img className="mr-2" src={'https://static.sky.co.nz/sky/icons/icon-color-wifi.svg'} alt="" />
              <span className="sky-h7-black">UNLIMITED&nbsp;</span>
              <span>fibre&nbsp;</span>
              <span className="sky-h7"> {fibreSpeed}</span>
            </li>
          </ul>

          <p className="sky-h6-reg">
            <span dangerouslySetInnerHTML={{ __html: plan.description }} />
            {savingsWithSkyTV > 0 ? (
              <span>
                Bundle with Sky TV and save <span className="font-bold">${savingsWithSkyTV}/mth</span> off Sky Broadband
                on a rolling monthly plan. Includes use of a Sky WiFi Router.
              </span>
            ) : null}
          </p>

          <p className="sm:sky-sub">
            {savingsWithSkyTV ? ` $${nonStarterPrice}/mth if you remove Sky Starter.` : ''}
            {savingsWithSkyTV ? (
              <>
                <a
                  className="underline cursor-pointer"
                  href="https://www.sky.co.nz/broadband/offers"
                  target="_blank"
                  rel="noreferrer noopener">
                  T&Cs
                </a>
                &nbsp;apply.
              </>
            ) : null}
          </p>
          <div className="flex 2xl:mt-10 justify-between items-center">
            <div className="flex">
              <Button className="ml-0" variant="secondary" colorMode="pure" onClick={toggleModal}>
                Explore
              </Button>
              <Button
                className="m-0 2xl:ml-3"
                colorMode={isPlanSelected ? 'pink' : 'pure'}
                icon={addBtnIcon}
                variant="primary"
                data-testid={'add-bb-product-' + plan.product?.sku!}
                onClick={addBtnAction}
                disabled={isUpdatingPlan}>
                {addBtnLabel}
              </Button>
            </div>
            <div className="flex flex-wrap">
              {isStarterInCart && savingsWithSkyTV > 0 && (
                <div>
                  <span className="line-through">{formatMoney(Number(plan.price.nonStarterPrice))}</span>
                </div>
              )}
              <FancyPrice
                price={
                  plan.bundle
                    ? Number(plan.price.fullPrice)
                    : isStarterInCart
                    ? Number(plan.price.fullPrice)
                    : Number(plan.price.nonStarterPrice)
                }
                numberStyles="md:sky-h1-black sky-h1 font-bold w-full text-68px"
                containerClasses="text-navy relative mx-6"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Mobile & Tablet */}
      <div className="h-full w-full pb-6 xl:hidden">
        <div
          className="card flex flex-1 flex-col rounded-lg overflow-hidden h-full"
          data-testid={`bb-plan-${plan.product?.sku!}`}>
          <div className="relative">
            {showBBOneMonthOnUs && (
              <div
                className="absolute w-100px h-100px"
                style={{
                  zIndex: 1,
                  right: '10px',
                  top: '10px',
                }}>
                <img className="w-full" src="https://static.sky.co.nz/sky/icons/1monthonus.svg" alt="" />
              </div>
            )}
            <Aspect ratio={9 / 16}>
              <img src={plan?.imgSrc} className="w-full h-full" alt=""></img>
            </Aspect>
          </div>
          <div
            className={`flex-1 text-navy p-5 flex flex-col items-center rounded-lg rounded-t-none border-t-0 ${
              !isPlanSelected ? 'border-2' : 'border-2 border-pink'
            }`}>
            {/* Logo image URL. */}
            <h3 className="sky-h4-black pb-6 h-auto flex flex-grow justify-center items-center">{plan.header.title}</h3>
            <div>
              <ul className="flex flex-wrap gap-4 sky-h7-reg text-12px justify-center">
                <li className="flex items-start">
                  <img className="mr-2" src={'https://static.sky.co.nz/sky/icons/icon-color-wifi.svg'} alt="" />
                  <span>
                    <span className="sky-h7-black mr-2"> UNLIMITED </span> Fibre
                    <span className="sky-h7 ml-2">{plan.product?.custom8}</span>
                  </span>
                </li>
              </ul>
            </div>

            <div className="mt-5 sky-h6-reg text-center">
              <span dangerouslySetInnerHTML={{ __html: plan.description }} />
              {savingsWithSkyTV > 0 ? (
                <span>
                  Bundle with Sky TV and save <span className="font-bold">${savingsWithSkyTV}/mth</span> off Sky
                  Broadband on a rolling monthly plan. Includes use of a Sky WiFi Router.
                </span>
              ) : null}
            </div>
            <div className="mt-3 sky-h8-reg text-12px text-center">
              {savingsWithSkyTV ? ` $${nonStarterPrice}/mth if you remove Sky Starter.` : ''}
              <a className="underline cursor-pointer" href={links.bbTerms} target="_blank" rel="noopener noreferrer">
                T&Cs
              </a>
              &nbsp;apply.
            </div>
            <div className="flex-1 mt-10"></div>
            <div>
              {isStarterInCart && savingsWithSkyTV > 0 && (
                <div>
                  <span className="line-through">{formatMoney(Number(plan.price.nonStarterPrice))}</span>
                </div>
              )}
              <FancyPrice
                price={
                  plan.bundle
                    ? Number(plan.price.fullPrice)
                    : isStarterInCart
                    ? Number(plan.price.fullPrice)
                    : Number(plan.price.nonStarterPrice)
                }
                numberStyles="md:sky-h1-black sky-h1 font-bold w-full text-68px"
              />
            </div>
            <div className="flex flex-row justify-around w-full">
              <Button variant="secondary" colorMode="pure" onClick={toggleModal}>
                Explore
              </Button>
              <Button
                className="m-0"
                colorMode={isPlanSelected ? 'pink' : 'pure'}
                icon={addBtnIcon}
                variant="primary"
                data-testid={'add-bb-product-' + plan.product?.sku!}
                onClick={addBtnAction}
                disabled={isUpdatingPlan}>
                {addBtnLabel}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <BBExploreModal
        isShown={showModal}
        toggleProduct={async (plan: any) => {
          addBtnAction();
        }}
        kkService={konakartService}
        hide={toggleModal}
        plan={plan}
        product={plan.product!}
        isStarterInCart={isStarterInCart ?? false}
        content={config?.broadband?.acquisition?.speedShowCase}
        configService={configService}
        parentPrice={
          plan.bundle ? plan.price.fullPrice : isStarterInCart ? plan.price.fullPrice : plan.price.nonStarterPrice
        }
      />
    </>
  );
};

export { Plan };
