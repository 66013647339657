import React from 'react';
import { useNewAcquisitionOrderStore } from '@sky-tv-group/shared';

let AddressBar = () => {
  const { guestPrequalData, selectedAddress } = useNewAcquisitionOrderStore(s => ({
    guestPrequalData: s.guestPrequalData,
    selectedAddress: s.selectedInstallationAddress,
  }));

  // TODO Brandify.
  // Test Addresses:
  // OK = 28 Burswood Drive, Burswood, Manukau, Auckland
  // FUTURE = 54 Pitcairn Crescent, Bryndwr, Christchurch
  // UNAVAILABLE = 41 Ototoika Road, Te Kuiti, Waitomo District

  return (
    <div>
      {guestPrequalData && (
        <div className="sky-text-white text-13px pl-7 py-3 pr-20 flex flex-col flex-wrap">
          <h3>
            <span className="font-bold">We have your current address as:</span> {selectedAddress}
          </h3>
        </div>
      )}
    </div>
  );
};

export { AddressBar };
