import { formatMoneyWithoutSignSplit } from '@sky-tv-group/shared';
import React from 'react';

interface IcePricingProps {
  price: number;
  showZeroAsFree?: boolean;
  showIncluded?: boolean;
  showPending?: boolean;
  period?: string;
}

const IcePricing = ({ price, showZeroAsFree = false, showIncluded, showPending, period = '/mth' }: IcePricingProps) => {
  const formattedPrice = formatMoneyWithoutSignSplit(price);
  const whole = formattedPrice[0] ?? '00';
  const decimal = formattedPrice[1] ?? '00';

  if (showZeroAsFree && price === 0) {
    return <span>Free</span>;
  } else if (showIncluded) {
    return <span>Included</span>;
  } else if (showPending) {
    return <span>Pending</span>;
  } else {
    return (
      <div className="flex flex-row">
        <div className="sky-h6-bold">$</div>
        <div className="sky-h1 px-1" style={{ fontSize: '4rem', lineHeight: '0.75' }}>
          {whole}
        </div>
        <div className="flex flex-col justify-between">
          <h5 className="sky-h6">.{decimal}</h5>
          <div className="text-xs">{period}</div>
        </div>
      </div>
    );
  }
};

export { IcePricing };
