import { MarketingInfo, SpeedShowCase } from '@sky-tv-group/components';
import { useJSONConfig } from '@sky-tv-group/shared';
import React from 'react';
import { configService } from '../../../services';

const MarketingContainer = () => {
  const config = useJSONConfig(configService);

  return (
    <>
      <SpeedShowCase data={config?.broadband?.acquisition?.speedShowCase} />
      <MarketingInfo />
    </>
  );
};

export { MarketingContainer };
