import bankValidator from 'nz-bank-account-validator/lib/NZ-Bank-Account-Validator';
import _isEqual from 'lodash.isequal';

import { NullableDate, Ownership, NullableAddressLookup, YesNoOptionValues } from '../types';
import { isSameDay } from 'date-fns';
import { unformattedTelPhoneNumber } from '../config';

const AREA_CODES = [
  '03',
  '04',
  '06',
  '07',
  '09',
  '020',
  '021',
  '022',
  '023',
  '024',
  '025',
  '027',
  '028',
  '029',
  '0280',
];

export function validatePassword(value: string) {
  let errs = [];
  if (value.length < 7) errs.push('Your password must be at least 7 characters long.');
  if (!/[a-zA-Z]/.test(value)) errs.push('Your password must contain as least one letter');
  if (!/[^a-zA-Z]/.test(value)) errs.push('Your password must contain at least one non-letter.');
  return errs;
}

export function validateIdentical<T>(val1: T, msg = 'Values dont match') {
  return function(val2: T) {
    let errs = [];
    if (val1 !== val2) {
      errs.push(msg);
    }
    return errs;
  };
}

export function validateFirstName(value: string) {
  let errs = [];
  if (!value.trim()) errs.push('First name is required');
  if (value.length < 2) errs.push('Minimum length 2 characters');
  if (value.length > 10) errs.push('Maximum length 10 characters');
  if (!/^[a-zA-Z\.\-\'‘\s]+$/.test(value)) errs.push('Invalid characters');
  if (value.split(' ').some(s => ['mr', 'mrs', 'miss', 'ms', 'mx', 'sir', 'dr'].includes(s.toLowerCase())))
    errs.push('No name tiles allowed');
  return errs;
}
export function validateFamilyName(value: string) {
  let errs = [];
  if (!value.trim()) errs.push('Family name is required');
  if (value.length < 2) errs.push('Minimum length 2 characters');
  if (value.length > 15) errs.push('Maximum length 15 characters');
  if (!/^[a-zA-Z\.\-\'‘\s]+$/.test(value)) errs.push('Invalid characters');
  if (value.split(' ').some(s => ['mr', 'mrs', 'miss', 'ms', 'mx', 'sir', 'dr'].includes(s.toLowerCase())))
    errs.push('No name tiles allowed');
  return errs;
}

export function validateDisplayName(value: any) {
  let errs = [];
  if (value.length === 0) errs.push('Display name is required');
  if (value.length > 30) errs.push('Must be 30 characters or less');
  return errs;
}

export function validateAreaCode(value: string) {
  let errs = [];
  if (!value) errs.push('Area code is required');
  if (value.length === 0) errs.push('Area code is required');
  if (!AREA_CODES.find(e => e == value)) errs.push('Area code is invalid');
  return errs;
}

export function validateTelephoneNumber(value: string) {
  let errs = [];
  if (!value) errs.push('Phone number is required');
  if (value.length === 0) errs.push('Phone number is required');
  if (!/^\d+$/.test(value)) errs.push('Phone number must only contain digits');
  if (value.length < 6) errs.push('Phone number must have minimum 6 digits');
  if (value.length > 8) errs.push('Phone number must be maximum 8 digits');
  if (/^0*$/.test(value)) errs.push('Phone number cannot be 0s');
  return errs;
}

export function validateLandlineAreaCode(value: string) {
  let errs = [];
  if (!value) errs.push('Area code is required');
  if (value.length === 0) errs.push('Area code is required');
  if (!['03', '04', '06', '07', '09'].includes(value)) {
    errs.push('Please enter a valid home phone area code');
  }
  return errs;
}

export function validateLandlineNumber(value: string) {
  let errs = [];
  if (!value) errs.push('Phone number is required');
  if (value.length === 0) errs.push('Phone number is required');
  if (!/^\d+$/.test(value)) errs.push('Phone number must only contain digits');
  if (value.length !== 7) errs.push('Phone number must be 7 digits');
  return errs;
}

export function validateMobileNumberAreaCode(value: string) {
  let errs = [];
  if (!value) errs.push('Area code is required');
  if (value.length === 0) errs.push('Area code is required');
  if (!/^\d+$/.test(value)) errs.push('Area code must only contain digits');
  if (!/^02[0-9]$/g.test(value)) errs.push('Area code must be 3 digits and begin with 02, e.g. 021');
  if (/^0*$/.test(value)) errs.push('Area code cannot be 0s');
  return errs;
}

export function validateMobileNumberLineNumber(value: string) {
  let errs = [];
  if (!value) errs.push('Enter a valid phone number');
  if (value.length === 0) errs.push('Enter a valid phone number');
  if (!/^\d+$/.test(value)) errs.push('Phone number must only contain digits');
  if (value.length < 6) errs.push('Your number should be a minimum of 6 digits');
  if (value.length > 8) errs.push('Phone number must be maximum 8 digits');
  if (/^0*$/.test(value)) errs.push('You can’t enter all 0’s even though it looks nice');
  return errs;
}

export function validateOtherServiceProvider(value: string) {
  let errs = [] as string[];
  // if (value.length < 2) errs.push('Current provider name must be at least 2 characters');
  // if (value.length > 30) errs.push('Current provider name must be maximum 30 characters');
  // if (/^ *$/.test(value)) errs.push('Current provider name cannot be all spaces');
  return errs;
}

export function validateEmail(value: string) {
  let errs: string[] = [];
  if (!value) errs.push('Email is required');
  if (value.includes(' ')) errs.push('Space is not allowed');
  if (value.length === 0) errs.push('Email is required');
  if (value.length > 80) errs.push('Email must be 80 characters of less');
  if (!/(.+)@(.+){1,}\.(.+){2,}/.test(value)) errs.push('Must be a valid email');
  return errs;
}

export function validateInstallationDate(value: NullableDate) {
  return validateRequiredDate('Installation Date')(value);
}

export function validateDateOfBirth(value: NullableDate) {
  return validateRequiredDate('Date Of Birth')(value);
}

export function validateRequiredDate(dateType: string, validateWhen?: () => boolean) {
  return (value: NullableDate) => {
    let errs = [];
    if (validateWhen === undefined || validateWhen()) {
      if (!value) errs.push(dateType + ' is required');
    }
    return errs;
  };
}

export let validationNumber = (value: string, msg = 'Must be numbers') => {
  let errs = [];
  if (!/^\d+$/.test(value)) {
    errs.push(msg);
  }
  return errs;
};

export let validationAlphaNumeric = (value: string, msg = 'Must be alphanumeric') => {
  let errs = [];
  if (!/^[a-zA-Z0-9\s]*$/.test(value)) {
    errs.push(msg);
  }
  return errs;
};

export let validationNumbers = (value: string[], msg = 'Must be numbers') => {
  let errs = [];
  if (value.some(v => !/^\d+$/.test(v))) {
    errs.push(msg);
  }
  return errs;
};

// this error msg is returned from MSL backend.
// MOBILE: 0 then 20-22,25,27-29 then 6-8 digits.
export function validateMobile(value: string) {
  let errs: string[] = [];
  if (!/^02[0-2,5,7-9][0-9]{6,8}$/.test(value)) errs.push('Invalid mobile number');
  return errs;
}
export function validatePhone(value: string) {
  let errs: string[] = [];
  if (!/^[0-9]{6,20}$/.test(value)) errs.push('Invalid phone number');
  return errs;
}

function validateAddress(addressType: string) {
  return (value: NullableAddressLookup) => {
    let errs: string[] = [];
    if (!value) errs.push(addressType + ' address is required');
    if (value) {
      if (value.label.length === 0) errs.push(addressType + ' address is required');
      if (value.label.length < 3) errs.push('Please enter at least 3 characters');
      if (value.id.length === 0)
        errs.push(
          `We can’t find that address in our system, please call our friendly Sky Crew on ${unformattedTelPhoneNumber} to get you started`
        );
    }
    return errs;
  };
}

export function validateBillingAddress(value: NullableAddressLookup) {
  return validateAddress('Billing')(value);
}

export function validateDeliveryAddress(value: NullableAddressLookup) {
  return validateAddress('Delivery')(value);
}

export function validateInstallationAddress(value: NullableAddressLookup) {
  return validateAddress('Installation')(value);
}

export const checkValidAddress = (address: NullableAddressLookup): boolean => {
  if (!address) return false;
  return address.label !== '' && address.id !== '';
};

export function validateOwnership(value: Ownership) {
  let errs: string[] = [];
  if (!value) errs.push('Please select property ownership ');
  if (value === '') errs.push('Please select property ownership ');
  return errs;
}

export function validateBankCard(value: string[]) {
  let errs: string[] = [];
  let bankcard = value.join('-');
  if (!bankValidator.validate(bankcard)) {
    errs.push('Account number is not valid');
  }
  return errs;
}

export function validateBankAccountNumber(value: string) {
  let errs: string[] = [];
  if (!bankValidator.validate(value)) {
    errs.push('Account Number is not valid');
  }
  return errs;
}

export let validationPinRequired = (value: string[]) => {
  let errs = [];
  if (value.some(v => v.length === 0)) {
    errs.push('Your pin must be 4 digits');
  }
  return errs;
};

export let validationPinStrRequired = (value: string) => {
  let errs = [];
  if (value.length < 4) {
    errs.push('Your pin must be 4 digits');
  }
  return errs;
};

export let validationPinMatch = (val1: string[]) => (val2: string[]) => {
  let errs = [];
  if (!_isEqual(val1, val2)) {
    errs.push('Your new and confirmation PINs do not match.');
  }
  return errs;
};

export function validateContactName(value: any) {
  let errs = [];
  if (!value) errs.push('Contact name is required');
  if (value.length === 0) errs.push('Contact name is required');
  if (value.length > 80) errs.push('Must be 80 characters or less');
  if (/[^-a-zA-Z.' ]/g.test(value)) errs.push('Please enter name without special characters');
  return errs;
}

export function validateNotes(value: string) {
  let errs: string[] = [];
  if (value.length > 130) errs.push('Notes must be 130 characters of less');
  return errs;
}

export function validateYesNoOption(value: YesNoOptionValues) {
  let errs: string[] = [];
  if (!value) errs.push('Please select an option');
  return errs;
}

export function validateProviderAccountNumber(value: string) {
  let errs = [];
  if (!value) errs.push('Account number is required');
  if (value.length === 0) errs.push('Account number is required');
  if (value.length > 9) {
    errs.push('Account number must be less than 9 digits');
  }
  return errs;
}

export function validatePhoneNumberFromList(value: string) {
  let errs = [];
  if (!value) errs.push('Please select a new home phone number');
  return errs;
}

export function validateBoxNickname(value: string) {
  let errs = [];
  if (value.length > 20) errs.push('Maximum length 20 characters');
  if (value.length) {
    if (!/^[a-zA-Z0-9\-\s]+$/.test(value)) errs.push('Invalid characters');
  }

  return errs;
}

export function validateDDAccountName(value: string) {
  let errs = [];
  if (value.length > 32) errs.push('Maximum length 32 characters');
  return errs;
}
