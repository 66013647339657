import React, { useEffect, useRef, useState } from 'react';
import { CheckoutStates, useUpgradableBoxesForm } from '@sky-tv-group/shared';
import { InstallationDetails, FormElement, Button, Login } from '@sky-tv-group/components';

import { next, back } from '../navigation';
import { addressService, configService, konakartService, myAccountSWR, technicianLookup } from '../../../services';
import { ACCOUNT_URL } from '../../../config';
import { useAcquisitionJourneyState } from '../../../provider/AcquisitionJourney';
import { useChoosePlan } from '../../../hooks/useChoosePlan';
import { BackButton } from '../../../components/back-button';
import { BreadcrumbContainer } from '../../../components/BreadcrumbContainer';

const DTHForm = () => {
  // sync account detail to upgradable boxes form, to be used in creating payload

  const {
    installationDetailsInstallationDateField,
    installationDetailsInstallationTimeField,
    form,
  } = useUpgradableBoxesForm(configService, konakartService, myAccountSWR);
  const [canweserve, setcanweserve] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [installationOptionsAvailable, setInstallationOptionsAvailable] = useState(true);
  const { progressBar } = useAcquisitionJourneyState();

  let stickyRef = useRef<HTMLDivElement>(null) as any;

  function handleStickyBarScroll() {
    let footerElement = document.querySelector('#footerTermsAndCond') as any;
    if (stickyRef.current) {
      if (window.scrollY >= document.body.scrollHeight - window.innerHeight) {
        footerElement.style.marginBottom = stickyRef.current.offsetHeight + 'px';
      } else {
        footerElement.style.marginBottom = '0px';
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleStickyBarScroll);
    return () => {
      window.removeEventListener('scroll', handleStickyBarScroll);
    };
  }, []);

  let setInstallationOptionsAvailability = (available: boolean): void => {
    setInstallationOptionsAvailable(true);
  };
  const handleCallback = (data: []) => {
    if (data.length > 0) {
      setcanweserve(false);
    } else {
      setcanweserve(true);
    }
  };

  const goNext = async () => {
    await form.submit();
    if (form.valid) {
      next(CheckoutStates.TV);
    }
  };

  const goBack = () => {
    back(CheckoutStates.TV);
  };

  const { broadbandProductInCart } = useChoosePlan();

  return (
    <div>
      <BreadcrumbContainer progressBarStatus={false} />
      <div className="container m-auto md:pb-10 lg:mt-16 lg:px-6 mt-4 text-navy" data-testid="dth-form">
        <div className="flex flex-col justify-center items-center py-4 md:w-100 lg:hidden">
          <BackButton buttontext="Go back to About You" onClick={() => back(CheckoutStates.TV)} />
        </div>
        <FormElement {...form.props}>
          <InstallationDetails
            titleText="Sky TV Installation"
            formValidation={form}
            installationDateField={installationDetailsInstallationDateField}
            installationTimeField={installationDetailsInstallationTimeField}
            setInstallationOptionsAvailability={setInstallationOptionsAvailability}
            addressService={addressService}
            configService={configService}
            technicianLookupService={technicianLookup}
            handleCallback={handleCallback}
          />
          <div className=" rounded-lg border overflow-hidden text-white my-12">
            {/* TODO T&Cs here? */}
            <div
              className=" block fixed
               min-w-full bottom-0 lg:static z-50 md:static left-0"
              ref={stickyRef}>
              <div
                className={`flex justify-between md:p-12 lg:p-12  min-w-full bottom-0 z-50 lg:shadow-none md:shadow-none lg:rounded-none md:rounded-none sticky-custom bg-white left-0 p-5`}>
                <Button
                  data-testid="back-to-build-my-plan-checkout-button"
                  colorMode="light"
                  variant="secondary"
                  onClick={goBack}
                  disabled={false}
                  className="hidden lg:block md:block">
                  Back
                </Button>
                <Button
                  data-testid="next-to-confirm-button"
                  variant="primary"
                  onClick={goNext}
                  className="min-w-full min-w-fit">
                  {broadbandProductInCart ? 'Next: Broadband Installation' : 'Next: View Order Details'}
                </Button>
              </div>
            </div>
          </div>
        </FormElement>
      </div>
    </div>
  );
};

export { DTHForm };
