/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-jeff';
import cx from 'classnames';

import {
  NullableAddressLookup,
  AddressLookup,
  productStoreApi,
  productSkuIds,
  customerStoreApi,
  useUpdateCart,
  useAccountDetails,
  OwnershipFields,
  Ownership,
  AddressService,
  KonakartService,
  validateEmail,
  formatMoney,
  useProductStore,
  useSplitIO,
  SPLITIO_KEY,
  UserGroupType,
} from '@sky-tv-group/shared';
import { ValidatedAddressSuggest } from '../validation/addressValidated';
import { SelectElement } from '../validation/selectValidated';
import { Switch } from '../switch';
import { ArrowDown, ArrowUp, Button, CheckIcon, ValidatedTextInput } from '../..';

interface IPropertyDetailsProps {
  titleText: string;
  formValidation: Form<string>;
  addressField: Field<NullableAddressLookup, string>;
  paperlessBillingField: Field<boolean, string>;
  serviceEmailField: Field<string, string>;
  addressSameAsInstallationAddressField: Field<boolean, string>;
  billingAddressField: Field<NullableAddressLookup, string>;
  ownershipField: Field<Ownership, string>;
  addressService: AddressService;
  konakartService: KonakartService;
  disableAddressField?: boolean;
  isAbandon?: boolean;
  showpropertyDetails?: boolean;
  handlePaymentDetails?: () => any;
  showPropertyDetailsCheck?: boolean;
  setShowPropertyDetails?: React.Dispatch<React.SetStateAction<any>>;
  showDetails?: boolean;
  showPaymentInfo?: boolean;
}

const PropertyDetails: React.FunctionComponent<IPropertyDetailsProps> = ({
  titleText,
  formValidation,
  addressField,
  paperlessBillingField,
  addressSameAsInstallationAddressField,
  billingAddressField,
  ownershipField,
  addressService,
  konakartService,
  disableAddressField,
  serviceEmailField,
  isAbandon,
  showpropertyDetails,
  handlePaymentDetails,
  showPropertyDetailsCheck,
  setShowPropertyDetails,
  showDetails,
  showPaymentInfo
}) => {
  const {
    installationDetails,
    propertyDetail,
    yourDetail,
    updateAddress,
    updateBillingAddress,
    updateBillingAddressSameAsInstallation,
    updatePaperlessBilling,
    updateRentOrOwn,
    updateServiceContactEmail,
  } = useAccountDetails(addressService);

  const { updateOrder } = useUpdateCart(konakartService);
  const [paperLessBillingToggleDisable, setPaperLessBillingToggleDisable] = useState(false);
  const placeholderAddressLookup = { label: 'placeholder-label', id: 'placeholder-id' };

  const { products, changeProductQuantity } = useProductStore();
  const paperBillingProduct = products.find(p => p.sku === productSkuIds.paperBilling.primary);
  const [showPriceIncreaseMsg] = useSplitIO(SPLITIO_KEY.SKYWEB_PAPERLESS_PRICE_INCREASE_REVEAL);

  const matchPlaceHolder = (address: AddressLookup): boolean => {
    return address.label === placeholderAddressLookup.label && address.id === placeholderAddressLookup.id;
  };

  const updatePaperLessBilling = async (paperBilling: boolean) => {
    if(isAbandon){
      addressSameAsInstallationAddressField.setValue(true)
      updateBillingAddressSameAsInstallation(true);
    }
    setPaperLessBillingToggleDisable(true);
    paperlessBillingField.props.onChange(paperBilling);
    let quantity = paperBilling ? 0 : 1;
    if (paperBillingProduct) {
      changeProductQuantity(paperBillingProduct.id, quantity);
      await updateOrder(customerStoreApi.getState().kk, productStoreApi.getState().getBasketItemsToAddToOrder());
    }
    paperlessBillingField.props.onChange(paperBilling);
    setPaperLessBillingToggleDisable(false);

    if (!paperBilling) {
      if (propertyDetail.serviceContactEmail && !validateEmail(propertyDetail.serviceContactEmail).length) {
        serviceEmailField.setValue(propertyDetail.serviceContactEmail);
      } else {
        serviceEmailField.setValue(yourDetail.email);
      }
    }
  };

  useEffect(() => {
    updateBillingAddressSameAsInstallation(addressSameAsInstallationAddressField.value);
  }, [addressSameAsInstallationAddressField.value]);

  // update fields to session storage state when changed
  useEffect(() => {
    if (addressField.value?.id !== propertyDetail.address?.id) {
      updateAddress(addressField.value);
    }
  }, [addressField.value]);

  useEffect(() => {
    updatePaperlessBilling(paperlessBillingField.value);
  }, [paperlessBillingField.value]);

  useEffect(() => {
    updateBillingAddress(billingAddressField.value);
  }, [billingAddressField.value]);

  useEffect(() => {
    updateServiceContactEmail(serviceEmailField.value);
  }, [serviceEmailField.value]);

  useEffect(() => {
    updateRentOrOwn(ownershipField.value);
  }, [ownershipField.value]);

  // when installation & billing address same
  // this is top here as default we set addressSameAsInstallationAddressField to true (for useEffect ordering)
  useEffect(() => {
    if (addressSameAsInstallationAddressField.value) {
      if (!billingAddressField.value?.label && !billingAddressField.value?.id) {
        // this is done to ensure that we pass validation - the value is irrelevant for api call
        billingAddressField.setValue(placeholderAddressLookup);
      }
    }
  }, [addressSameAsInstallationAddressField.value]);

  // initialize values from session storage
  useEffect(() => {
    if (propertyDetail.billingAddressSameAsInstallationAddress !== addressSameAsInstallationAddressField.value) {
      addressSameAsInstallationAddressField.setValue(propertyDetail.billingAddressSameAsInstallationAddress);
    }
    if (propertyDetail.billingAddress) {
      billingAddressField.setValue(propertyDetail.billingAddress);
    }
    if (propertyDetail.address) {
      addressField.setValue(propertyDetail.address);
    }
    if (paperlessBillingField.value !== propertyDetail.paperlessBilling) {
      paperlessBillingField.setValue(propertyDetail.paperlessBilling);
    }
    if (propertyDetail.serviceContactEmail) {
      serviceEmailField.setValue(propertyDetail.serviceContactEmail);
    } else if (yourDetail.email && validateEmail(yourDetail.email)) {
      serviceEmailField.setValue(yourDetail.email);
    }
    if (installationDetails.rentOrOwn) {
      ownershipField.setValue(installationDetails.rentOrOwn);
    }
  }, []);

  // when installation & billing address different
  useEffect(() => {
    if (!addressSameAsInstallationAddressField.value) {
      // check to see its not the dummy data we had previously
      if (billingAddressField.value && matchPlaceHolder(billingAddressField.value)) {
        // set null to prevent the existing selected address if its the placeholder text
        billingAddressField.setValue(null);
      }
    }
  }, [addressSameAsInstallationAddressField.value]);

  const billingAddressClassName = cx('w-full', {
    'bg-gray-light': addressSameAsInstallationAddressField.value,
  });

  return (
    <>
      {titleText && !isAbandon && (
        <h4 className="bg-gray-light sky-h3 md:sky-h4 text-center h-24 p-4 flex justify-center items-center font-bold">
          {titleText}
        </h4>
      )}

      {isAbandon ? (
        <>
          <div className="flex flex-col md:flex-row md:flex-wrap p-6 md:p-0 md:py-2">
            <div className="flex flex-col mb-3 w-full " onClick={() => {
                      if (showPropertyDetailsCheck && setShowPropertyDetails) {
                        setShowPropertyDetails(true);
                      }
                    }}>
              <div className="flex w-full sky-label sky-text-midnight">2 of 3</div>
              <div className="flex flex-row  md:w-full justify-between sky-h4-black md:sky-h6-black">
                <div>Your Property</div>
                <div className="flex flex-row">
                  {showPropertyDetailsCheck && (
                    <div className="h-8 md:h-6 w-8 md:w-6 mr-2 rounded-full bg-skyGreen">
                      <CheckIcon color="white" />
                    </div>
                  )}
                  <button style={{cursor: showDetails ? 'auto' :'pointer'}}
                    >
                    <span>{showpropertyDetails ? <ArrowUp /> : <ArrowDown />}</span>
                  </button>
                </div>
              </div>
            </div>
            {showpropertyDetails && (
              <>
                <div className="pb-4 w-full md:w-1/2">
                  <label className="block sky-label md:sky-label mb-2">Physical Address</label>
                  <ValidatedAddressSuggest
                    addressField={addressField}
                    formValidation={formValidation}
                    id="account-details-property-details-address"
                    className="w-full"
                    addressType="Address"
                    addressService={addressService}
                    placeholder="Please start typing your address..."
                    disabled={disableAddressField}
                  />
                </div>
                <div className="md:pl-2 w-full md:w-1/2 pb-4">
                  <label className="block sky-label md:sky-label mb-2">Do you own or rent this property?</label>
                  <SelectElement
                    id="account-details-installation-details-own-or-rent"
                    className="w-full"
                    fieldValidation={ownershipField}
                    formValidation={formValidation}
                    onChange={ownershipField.props.onChange}
                    value={ownershipField.value}>
                    <option value="" disabled>
                      Select an Option
                    </option>
                    <option className="sky-input sky-input--option" value="Own">
                      {OwnershipFields.Own}
                    </option>
                    <option className="sky-input sky-input--option" value="Rent">
                      {OwnershipFields.Rent}
                    </option>
                  </SelectElement>
                  {ownershipField.value === OwnershipFields.Rent && (
                    <p className="sky-sub md:sky-sub pt-2">
                      You will need the landlord's written permission to install Sky.
                    </p>
                  )}
                </div>
                <div className="flex flex-col md:flex-row md:w-full">
                  <div className="pb-4 pr-0 md:pr-2 w-full md:w-1/2">
                    <label className="block sky-label md:sky-label mb-2">Email Address</label>
                    <ValidatedTextInput
                      fieldValidation={serviceEmailField}
                      formValidation={formValidation}
                      id="account-details-your-details-service-email"
                      className="w-full"
                      type="email"
                      onChange={serviceEmailField.props.onChange}
                      placeholder="john.smith@mail.com"
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          {showpropertyDetails && (
            <div className="flex justify-center md:justify-start pb-4 mt-2 min-w-full px-4 ">
              <Button colorMode="pure"  className ='min-w-full min-w-fit px-4 ' variant="secondary" disabled={false} onClick={() => { if(handlePaymentDetails) {  updatePaperLessBilling(true); handlePaymentDetails(); } }}>
                {`Next: Enter Payment Details`}
              </Button>
            </div>
          )}
        </>
      ) : (
        <div className="flex flex-col md:flex-row md:flex-wrap p-6 md:p-0 md:py-6">
          <div className="pb-4 w-full md:w-1/2">
            <label className="block sky-label md:sky-label mb-2">Address</label>
            <ValidatedAddressSuggest
              addressField={addressField}
              formValidation={formValidation}
              id="account-details-property-details-address"
              className="w-full"
              addressType="Address"
              addressService={addressService}
              placeholder="Please start typing your address..."
              disabled={disableAddressField}
            />
          </div>
          <div className="md:pl-2 w-full md:w-1/2">
            <label className="block sky-label md:sky-label mb-2">Do you own or rent this property?</label>
            <SelectElement
              id="account-details-installation-details-own-or-rent"
              className="w-full"
              fieldValidation={ownershipField}
              formValidation={formValidation}
              onChange={ownershipField.props.onChange}
              value={ownershipField.value}>
              <option value="" disabled>
                Select an Option
              </option>
              <option className="sky-input sky-input--option" value="Own">
                {OwnershipFields.Own}
              </option>
              <option className="sky-input sky-input--option" value="Rent">
                {OwnershipFields.Rent}
              </option>
            </SelectElement>
            {ownershipField.value === OwnershipFields.Rent && (
              <p className="sky-sub md:sky-sub pt-2 text-lg md:text-sm">You will need the landlord's written permission to install Sky.</p>
            )}
          </div>
          <div className="flex justify-between items-end pb-4 pt-12 w-full">
            <div className="max-w-10/12">
              <p className="sky-label font-bold">Billing preferences</p>
              <p className="pt-2">Paperless billing — Receive your bills via email </p>
              {!paperlessBillingField.value && paperBillingProduct && (
                <>
                  <p className="py-2 sky-sub md:sky-sub">
                    {`Paper bills will be charged ${formatMoney(paperBillingProduct.price0)} extra per month`}
                  </p>
                  {showPriceIncreaseMsg && (
                    <p className="sky-sub md:sky-sub">
                      From 1 February 2023 the price of a Sky bill via post will increase to $1.50
                    </p>
                  )}
                </>
              )}
            </div>
            <div>
              <Switch
                value={paperlessBillingField.value}
                disable={paperLessBillingToggleDisable}
                onChange={updatePaperLessBilling}
              />
            </div>
          </div>
          {paperlessBillingField.value ? (
            <div className="flex flex-col md:flex-wrap md:pb-6 w-full">
              <label className="block sky-label md:sky-label mb-2">Email Address</label>
              <ValidatedTextInput
                fieldValidation={serviceEmailField}
                formValidation={formValidation}
                id="account-details-your-details-service-email"
                className="w-full"
                type="email"
                onChange={serviceEmailField.props.onChange}
                placeholder="john.smith@mail.com"
              />
            </div>
          ) : (
            <>
              <div className="flex justify-between items-center pb-4 w-full">
                <div className="max-w-10/12">
                  <p className="py-2">Billing address same as installation address</p>
                </div>
                <div>
                  <Switch
                    value={addressSameAsInstallationAddressField.value}
                    onChange={addressSameAsInstallationAddressField.props.onChange}
                  />
                </div>
              </div>
              {!addressSameAsInstallationAddressField.value && (
                <div className="flex flex-col lg:flex-row w-full">
                  <div className="pb-4 w-full">
                    <label className="block sky-label md:sky-label mb-2">Billing address</label>
                    <ValidatedAddressSuggest
                      addressField={billingAddressField}
                      formValidation={formValidation}
                      id="account-details-property-details-billing-address"
                      className={billingAddressClassName}
                      addressType="Postal"
                      disabled={addressSameAsInstallationAddressField.value}
                      addressService={addressService}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export { PropertyDetails };
