import React from 'react';
import { TwoOptionsSelector } from '@sky-tv-group/components';
import { SkyCustomerYesNoOption, YesNoValueType } from '../../../types';
import { useNewAcquisitionOrderStore } from '@sky-tv-group/shared';

const VulnerableCustomerInfo = () => {
  const { customerOption, setCustomerOption } = useNewAcquisitionOrderStore(s => ({
    setCustomerOption: (value: YesNoValueType) => s.setPersistedButtonStates('vulnerableCustomerInfo', value),
    customerOption: s.persistedButtonStates.vulnerableCustomerInfo,
  }));
  return (
    <>
      <div className="sky-bg-gray-lightest p-6 my-6">
        <span className="sky-h7-reg sky-text-red text-bold">Vulnerable Customers: </span>
        <span className="sky-h7-reg">
          Are you, or someone in your household, a 111 Vulnerable Consumer? If you are it’s important to understand that
          fibre services, including your Sky Home Phone, will not work in a power cut. So, you’ll need an alternative
          way to contact 111 emergency services. This could be having a charged mobile phone or using a power back-up
          device. Under the Commerce Commission’s 111 Contact Code, it’s our responsibility to provide extra support to
          any people who qualify, whether it be for health, disability or safety reasons. We’re here to help, so
          whatever your situation, we’ll happily provide you with our Sky Home Phone service and if there happens to be
          a power cut, we’ll offer additional support to help you access 111 emergency services.
        </span>
      </div>
      <div className="border border-solid border-gray-dark h-full mx-auto">
        <div className="flex flex-col md:flex-row p-6 justify-between items-center">
          <div className="w-full md:w-2/3 sky-h7-bold md:sky-h7-bold pb-4 md:pb-0">
            Are you, or someone in your household, a 111 Vulnerable Consumer?
          </div>
          <div className="w-full md:w-1/3 px-4">
            <TwoOptionsSelector
              onSelectChange={setCustomerOption}
              valueLeft={SkyCustomerYesNoOption.YES}
              valueRight={SkyCustomerYesNoOption.NO}
              valueSelected={customerOption}
            />
          </div>
        </div>
        {customerOption === SkyCustomerYesNoOption.YES && (
          <div className="sky-bg-gray-lightest p-6 sky-h7-reg">
            To apply for additional support as a 111 Vulnerable Consumer you can download and complete a Vulnerable
            Consumer form after you’ve completed your order. We’ve got some handy information about it all right{' '}
            <a
              href="https://help.sky.co.nz/s/article/Vulnerable-consumers"
              target="_blank"
              rel="noreferrer noopener"
              className={'text-blue-light underline'}>
              {' '}
              here
            </a>
            .{' '}
            <a
              href="https://static.sky.co.nz/support/docs/Sky%20Broadband%20VC%20form.pdf"
              target="_blank"
              rel="noreferrer noopener"
              className={'text-blue-light underline'}>
              Get form here &gt;
            </a>
          </div>
        )}
      </div>
    </>
  );
};

export { VulnerableCustomerInfo };
