import React from 'react';

import { T_Product, useVoice, productSkuIds, useNewAcquisitionOrderStore } from '@sky-tv-group/shared';

import { konakartService, myAccountSWR, prequalService } from '../../services';
import { PackageBuy } from '../package/PackageBuy';
import { VulnerableCustomerInfo } from '../choose-plan/vulnerable-customer-info';

export let Voice = () => {
  const { guestPrequalData: prequal } = useNewAcquisitionOrderStore(s => ({
    guestPrequalData: s.guestPrequalData,
  }));
  const {
    voiceProducts,
    voiceAddonsProducts,
    voiceCrossCountryProducts,
    isHomePhoneOn,
    toggle,
    toggleCrossCountry,
  } = useVoice(konakartService, prequalService, myAccountSWR, prequal, false /* dont check for free */);

  const isVoiceAddable = prequal?.isVoiceAddable();

  const specialToggleLabel = "I'd like a home phone";

  if (!isVoiceAddable) {
    return null;
  }

  return (
    <div className="p-2 md:px-0 h-full mx-auto mb-4" data-testid={'voice-container'}>
      {voiceProducts
        ?.filter(
          (p: T_Product) =>
            ![
              productSkuIds.voiceSummerSet.primary as string,
              productSkuIds.voiceSummerSetOpt.primary as string,
            ].includes(p?.sku)
        )
        ?.map((p: T_Product) => (
          <PackageBuy key={p.id} data={p} toggle={toggle} showZeroAsFree={true} toggleLabel={specialToggleLabel} />
        ))}
      {isHomePhoneOn && (
        <>
          {/* Move Vulnerable Customer Info here for more visibility. */}
          <VulnerableCustomerInfo />

          {voiceAddonsProducts.length > 0 && (
            <p className="sky-h6-bold md:sky-h6-bold p-6 text-blue-pure">Want unlimited mobile & landline calling?</p>
          )}
          {voiceAddonsProducts.map((p: T_Product) => (
            <div key={p.id}>
              <PackageBuy data={p} toggle={toggle} showZeroAsFree={true} />
            </div>
          ))}
          {voiceCrossCountryProducts.length > 0 && (
            <div className="p-6 ">
              <p className="sky-h6-bold md:sky-h6-bold text-blue-pure">International calling options</p>
              <p>
                You can choose to select unlimited calls to mobiles and landlines in either the top 10 or top 20
                international calling destinations (beyond Australia).
              </p>
            </div>
          )}
          {voiceCrossCountryProducts.map((p: T_Product) => (
            <div key={p.id}>
              <PackageBuy data={p} toggle={toggleCrossCountry} showZeroAsFree={true} />
            </div>
          ))}
        </>
      )}
    </div>
  );
};
