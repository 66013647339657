import React, { useEffect } from 'react';
import get from 'lodash/get';
import { useField, useForm } from 'react-jeff';
import {
  validateInstallationAddress,
  NullableAddressLookup,
  formatAddress,
  useBroadbandStore,
  useBroadbandDeliveryStore,
  useAccountDetailsStore,
  BroadbandReady,
  segment,
  useProductWithCouponRemoval,
  useRemoveProduct,
  CouponTypeValue,
  useNewAcquisitionOrderStore,
} from '@sky-tv-group/shared';
import { ValidatedAddressSuggest, BlockLoader } from '@sky-tv-group/components';
import { addressService, konakartService, prequalService } from '../../services';

import { useCheckYourAddress } from '../../hooks/useCheckYourAddress';
import { FibreDescription } from './FibreDescription';
import { useAuth0 } from '../../store/authStore/authStore';
import { AddressBar } from './AddressBar';
import { useChoosePlan } from '../../hooks/useChoosePlan';

const CheckYourAddress = () => {
  const { isAuthenticated } = useAuth0(s => ({
    isAuthenticated: s.isAuthenticated,
    login: s.login,
  }));
  const { clearBroadbandStore } = useBroadbandStore();
  const { clearBroadbandDeliveryStore } = useBroadbandDeliveryStore();
  const { clearAccountDetails } = useAccountDetailsStore();
  const { broadbandProductInCart } = useChoosePlan();

  const { handleRemovalOfT_OrderProductWithToast } = useProductWithCouponRemoval(konakartService);
  const { toggleCartProduct } = useRemoveProduct(konakartService, CouponTypeValue.Acquisition);

  const {
    isLoadingPrequal,
    setSelectedAddress,
    setSelectedTuiAddress,
    setSelectedAddressId,
    setGuestPrequal,
    clearStore,
    setIsLoadingPrequal,
  } = useNewAcquisitionOrderStore(s => ({
    isLoadingPrequal: s.isLoadingPrequal,
    setSelectedAddress: s.setSelectedInstallationAddress,
    clearStore: s.clearStore,
    setSelectedTuiAddress: s.setSelectedTuiAddress,
    setSelectedAddressId: s.setSelectedAddressId,
    setGuestPrequal: s.setGuestPrequal,
    selectedTuiAddress: s.selectedTuiAddress,
    guestPrequalData: s.guestPrequalData,
    setIsLoadingPrequal: s.setIsLoadingPrequal,
  }));

  const { isFibreOk, isFibreFuture, isFibreNeverOrUnknown, getTitle, getWifiGuy } = useCheckYourAddress();

  const installationAddress = useField<NullableAddressLookup>({
    defaultValue: { id: '', label: '' },
    required: true,
    validations: [validateInstallationAddress],
  });

  const submit = function() {};
  const form = useForm({
    fields: Object.values(installationAddress),
    onSubmit: submit,
  });

  useEffect(() => {
    async function prequalify(addressId: string) {
      try {
        setSelectedAddressId(addressId);

        const addressDetails = await addressService.getAddressDetail(addressId);
        const tui = get(addressDetails, 'references.tui', undefined);

        if (!tui) {
          throw new Error('Error');
        }

        setSelectedTuiAddress(tui);
        setIsLoadingPrequal(true);

        const prequal = await prequalService.getPrequalDetails(tui);
        if (prequal) {
          const selectedAddressLines = formatAddress(
            addressDetails.formatted_delivery_address.line1,
            addressDetails.formatted_delivery_address.line2,
            addressDetails.formatted_delivery_address.line3,
            addressDetails.formatted_delivery_address.line4
          );

          setGuestPrequal(prequal);
          setSelectedAddress(installationAddress?.value?.label, selectedAddressLines);

          // analytics
          const broadbandReadyStatus =
            prequal.getBroadbandReadyStatus().broadbandReadyStatus === BroadbandReady.FibreOK
              ? 'Fibre Available'
              : prequal.getBroadbandReadyStatus().broadbandReadyStatus === BroadbandReady.FibreFuture
              ? 'Fibre Not Yet Available'
              : 'Fibre Unavailable';

          segment.prequalAddressChecked(isAuthenticated, broadbandReadyStatus);
        }

        setIsLoadingPrequal(false);
      } catch (err) {
        console.log('Error');
      }
    }

    if (installationAddress?.value && installationAddress.value.id !== '') {
      prequalify(installationAddress.value.id);
    }
    // eslint-disable-next-line
  }, [installationAddress.value]);

  // Clears local storage information if a new address is selected in
  // the address bar
  const clearLocalDataStores = () => {
    clearStore();
    clearAccountDetails();
    clearBroadbandStore();
    clearBroadbandDeliveryStore();
  };

  const resetInstallationAddressField = () => {
    clearLocalDataStores();
    installationAddress.reset();
    setSelectedTuiAddress('');
    if (broadbandProductInCart) {
      handleRemovalOfT_OrderProductWithToast(
        { name: broadbandProductInCart.product.name, id: broadbandProductInCart.product.id },
        toggleCartProduct,
        true
      );
    }
  };

  let getTitleBackgoundColor = () => {
    return isFibreFuture()
      ? 'bg-bborange sky-text-white'
      : isLoadingPrequal
      ? ''
      : isFibreNeverOrUnknown()
      ? 'sky-bg-red sky-text-white'
      : 'bg-skyGreen sky-text-white';
  };

  return (
    <div className="sky-header-gradient rounded-lg mx-3 md:mx-0 md:w-full">
      <div className="relative">
        <div className={`flex flex-row px-7 pt-3 rounded-t-lg ${getTitleBackgoundColor()}`}>
          <div className="w-8/12">{!isLoadingPrequal && <div className="sky-h5-black pb-2">{getTitle()}</div>}</div>
        </div>

        <AddressBar />
        <div className="px-7 pb-5">
          {!isLoadingPrequal && (
            <div className="w-8/12 sky-text-white">
              <FibreDescription clearInstallationAddressField={resetInstallationAddressField} />
            </div>
          )}
          {!isFibreOk() && (
            <ValidatedAddressSuggest
              addressField={installationAddress}
              formValidation={form}
              id="address"
              addressType="Address"
              addressService={addressService}
              placeholder="Enter your address"
              className="w-8/12 p-3"
              disabled={isLoadingPrequal}
            />
          )}
        </div>

        {isLoadingPrequal && <BlockLoader style={{ height: '4rem' }} />}
      </div>
    </div>
  );
};

export { CheckYourAddress };
