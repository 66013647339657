import React, { FC, ReactElement } from 'react';
import {
  SPLITIO_KEY,
  T_Product,
  useSplitIO,
  useCustomerStore,
  useIdentifyBox,
  BoxUpgradeStatus,
  Box,
  T_Billing,
  categoryIds,
} from '@sky-tv-group/shared';
import { ICEBoxCard } from './box-line-items/ICEBoxCard';
import { CarouselContainer } from './carousel/CarouselContainer';
import { SourceJourney } from './SkyBoxPrimary';

const MAX_BOX_ALLOWED = 6;
const slickSettings = {
  autoSlidesToShow: true,
  variableWidth: true,
  infinite: false,
  arrows: false,
  dots: false,
  customClassName: 'custom-carousel slick-slide-px-r-20 slick-slide-px-r-30 -mr-10vw md:mr-0',
  responsiveBlock: [],
};

interface SkyBoxMultiroomProps {
  // Newly added and subscribed boxes from the box store.
  boxes: Box[];

  // MSL billing response JSON for calculating box status.
  billing?: T_Billing;

  // Header text of the multiroom section.
  heading: string;

  manageNicknameButton?: (box: Box) => JSX.Element;

  // The edit box button modal element
  updateBoxButton?: (box: Box) => JSX.Element | undefined;

  // The add multiroom button element generator
  addMultiroom: () => ReactElement;

  // The edit multiroom button element generator
  editMultiroom: (box: Box) => ReactElement;

  source?: string;
}

const SkyBoxMultiroom: FC<SkyBoxMultiroomProps> = ({
  boxes,
  billing,
  heading,
  manageNicknameButton,
  updateBoxButton,
  addMultiroom,
  editMultiroom,
  source,
}) => {
  const [allowAddingMultiRoom] = useSplitIO(SPLITIO_KEY.SKYWEB_MULTIROOM_ADD);
  const [newSkyBoxCSGEnabled] = useSplitIO(SPLITIO_KEY.SKYWEB_NEW_SKY_BOX_CSG);

  const { hasPendingCableWorkOrder } = useCustomerStore();
  const { getBox, getBoxStatus, isBoxEligibleForCSG, doesBoxHaveFreeMySky } = useIdentifyBox();

  const subscribedBoxes = boxes?.filter(b => b.boxType === 'SUBSCRIBED');
  const newlyAddedBox = boxes?.filter(b => b.boxType === 'NEW');
  const multiroomBoxes = subscribedBoxes?.filter(b => !b.primary);

  const pendingBoxes = boxes?.filter(b => b.boxType === 'PENDING');
  const newlyAddedMultiroomBoxes = boxes?.filter(b => b.boxType === 'NEW' && !b.primary);

  const numberOfDevices = subscribedBoxes.length + newlyAddedBox.length;

  const multiRoomCards = (addMoreButton: boolean): JSX.Element[] => {
    let cards: JSX.Element[] = [];

    if (addMoreButton && allowAddingMultiRoom && numberOfDevices < MAX_BOX_ALLOWED && !hasPendingCableWorkOrder) {
      cards.push(addMultiroom());
    }

    // existing MR
    multiroomBoxes?.forEach(box => {
      /* Get box. */
      const product: T_Product = getBox(box.occurence);

      if (!product || !updateBoxButton || !manageNicknameButton) return null;
      /* Get status of the box. */
      const serviceStatus = getBoxStatus(box, billing);
      cards.push(
        <ICEBoxCard
          key={box.id ?? box.occurence?.serialNumber}
          box={box}
          product={product}
          status={serviceStatus}
          manageNicknameButton={manageNicknameButton(box)}
          actionButton={updateBoxButton({ ...box, serviceCode: product.sku })}
          isMultiroomBox={true}
          isCSGEligible={
            billing && box.occurence && !!newSkyBoxCSGEnabled && isBoxEligibleForCSG(billing, box.occurence, product)
          }
          hasFreeMySky={billing && doesBoxHaveFreeMySky(billing, box)}
        />
      );
    });

    pendingBoxes?.forEach(box => {
      /* Get box. */
      const product: T_Product = getBox(box.occurence);
      if (!product) return null;
      /* Get status of the box. */
      const serviceStatus = getBoxStatus(box, billing);

      cards.push(
        <ICEBoxCard
          key={box.id ?? box.occurence?.serialNumber}
          box={box}
          product={product}
          status={serviceStatus}
          isMultiroomBox={true}
        />
      );
    });

    newlyAddedMultiroomBoxes?.forEach((box, index) => {
      // assume first one is the base box.
      const product = box.products.find(p => p.categoryId === categoryIds.box);
      cards.push(
        <ICEBoxCard
          key={`${box.id}`}
          product={product}
          box={box}
          status={hasPendingCableWorkOrder ? BoxUpgradeStatus.PROCESSING : BoxUpgradeStatus.IN_CART}
          actionButton={editMultiroom({ ...box, serviceCode: product?.sku })}
          isMultiroomBox={true}
        />
      );
    });

    return cards;
  };

  return (
    <>
      <div className="flex flex-row justify-between items-center">
        {source === SourceJourney.WEB_ICE ? (
          <h3 className="relative card text-navy flex flex-wrap items-center" data-testid={heading}>
            <span className="sky-h3-black md:sky-h4-black">{heading.split(' ')[0]}</span>
            <span className="sky-h3-reg md:sky-h4-reg ml-2">{heading.split('Add')}</span>
          </h3>
        ) : (
          <h3
            className="relative card sky-h5-black md:sky-h5-black text-navy flex flex-wrap items-center"
            data-testid={heading}>
            {heading}&nbsp;
          </h3>
        )}
      </div>
      <h4 className="md:flex relative pt-2 pb-8 items-center sky-h6-reg md:header-description text-navy">
        {'Get another Sky Box installed so that people in another room can watch different channels. (Max. 5)'}
      </h4>
      <div className="block mt-6 md:hidden">
        <CarouselContainer {...slickSettings}>
          {multiRoomCards(true).map((c, index) => (
            <div className="w-270px mt-6 pb-12" key={index}>
              {c}
            </div>
          ))}
        </CarouselContainer>
      </div>
      <div className="hidden mt-6 md:block">
        <div
          className="px-1 md:grid md:grid-cols-cards-3 md:gap-8 mt-6"
          style={{ gridTemplateColumns: 'repeat(auto-fill, 270px)' }}>
          {multiRoomCards(true)}
        </div>
      </div>
    </>
  );
};

export { SkyBoxMultiroom };
