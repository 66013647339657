import React from 'react';
import {
  BoxUpgradeStatus,
  T_Occurrence,
  T_Product,
  productSkuIds,
  Box,
  categoryIds,
  useboxesStore,
  useIdentifyRecording,
  useIdentifyBox,
} from '@sky-tv-group/shared';
import { T_Billing } from '@sky-tv-group/shared/src/types';
import { Checked, MySkyOnUsBadge, Unchecked } from '../icon';
export let isArrow = (box?: T_Occurrence, product?: T_Product) => {
  if (!box) {
    if (
      product &&
      (product.sku === productSkuIds.arrowBox.primary ||
        product.sku === productSkuIds.arrowBoxBlack.primary ||
        product.sku === productSkuIds.arrowBoxOneOff.primary)
    ) {
      return true;
    }

    return false;
  }
  let entCodes = box.entitlements.map(ent => ent.code);
  return entCodes.includes(productSkuIds.arrowBox.primary) || entCodes.includes(productSkuIds.arrowBoxBlack.primary);
};
export let isArrowBlack = (box?: T_Occurrence, product?: T_Product) => {
  if (!box) {
    if (
      product &&
      (product.sku === productSkuIds.arrowBox.primary ||
        product.sku === productSkuIds.arrowBoxBlack.primary ||
        product.sku === productSkuIds.arrowBoxOneOff.primary)
    ) {
      return true;
    }

    return false;
  }

  let entCodes = box.entitlements.map(ent => ent.code);
  return entCodes.includes(productSkuIds.arrowBoxBlack.primary);
};

export interface ICEBoxCardProps {
  // The box's konakart product object
  product?: T_Product;
  // Editing box
  box?: Box;
  // Account billing
  billing?: T_Billing;
  // Box occurrence status
  status: BoxUpgradeStatus | null;
  // Edit/upgrade button
  actionButton?: JSX.Element;
  // Manage nickname button
  manageNicknameButton?: JSX.Element;
  // is multiroom box
  isMultiroomBox?: boolean;
  readonly?: boolean;
  isCSGEligible?: boolean;
  minHeight?: number;
  //use on identify box modal
  isIdentifyBoxCard?: boolean;
  handleBoxSelection?: (value: any) => void;
  isBoxSelected?: boolean;
  hasFreeMySky?: boolean;
}

const ICEBoxCard: React.FC<ICEBoxCardProps> = ({
  product,
  box,
  status,
  actionButton,
  manageNicknameButton,
  isMultiroomBox = false,
  isCSGEligible,
  minHeight,
  isIdentifyBoxCard,
  handleBoxSelection,
  isBoxSelected,
  hasFreeMySky,
}) => {
  const { getUpgradingBoxFromOccurenceNo } = useboxesStore();
  const { getRecording, getRecordingInProducts } = useIdentifyRecording();
  const { formatBoxName } = useIdentifyBox();

  if (!product) return null;

  // existing box' recording functionality
  const recording = getRecording(box?.occurence) ?? getRecordingInProducts(box?.products ?? []);
  // upgradingToBox and upgradeProduct is the box being upgraded to (e.g. Sky Box -> New Sky Box).
  const upgradingToBox = getUpgradingBoxFromOccurenceNo(box?.occurence?.occurrenceNumber || '');
  const upgradeProduct = upgradingToBox?.products.find(
    b => b.categoryId === categoryIds.box || b.sku === productSkuIds.arrowBoxRecording.primary
  );

  const boxHasRecording = recording?.sku !== productSkuIds.noRecording.primary;
  const isSkyPod = product.sku === productSkuIds.skyPod.primary;
  const isNSB =
    product.sku === productSkuIds.arrowBox.primary ||
    product.sku === productSkuIds.arrowBoxBlack.primary ||
    product.sku === productSkuIds.arrowBoxOneOff.primary;

  const Label = () => {
    let content: string | null = null;

    /* Upgrade message. */
    if (status === BoxUpgradeStatus.UPGRADING) {
      content = 'Review & confirm your upgrade';
    }
    // upgrading is in progress
    else if (status === BoxUpgradeStatus.PROCESSINGUPGRADING) {
      content = 'Processing to upgrade';
    }
    // If this box is set to update but order has not being submitted yet
    else if (!isMultiroomBox && status === BoxUpgradeStatus.IN_CART) content = 'Upgrading to ' + upgradeProduct?.name;
    // Adding a new multiroom device but order has not been submitted
    else if (isMultiroomBox && status === BoxUpgradeStatus.IN_CART) {
      content = 'Adding new device';
    }
    // Adding a new multiroom device and order has been submitted
    else if (isMultiroomBox && status === BoxUpgradeStatus.PROCESSING) content = 'Processing new box order';
    /* Generic message. */ else if (upgradeProduct?.name !== null && upgradeProduct?.name !== undefined) {
      content = 'Processing request';
    }

    if (content !== null) {
      return (
        <div
          className="rounded-md border-l-6 inline-block px-3 py-2
             bg-pink-cool  ">
          <p className="text-md">{content}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      {/* Don't overflow-hidden because it cuts the label. */}
      <div
        className={`flex flex-col flex-shrink-0 border border-gray-dark rounded-md w-270px relative h-full ${
          isMultiroomBox ? 'min-h-330px lg:min-h-350px' : ''
        } ${isIdentifyBoxCard ? 'cursor-pointer hover:border-gray-copy' : ''} ${
          isIdentifyBoxCard && isBoxSelected ? 'border-gray-copy' : ''
        }`}
        style={{ minHeight: minHeight }}
        onClick={() => isIdentifyBoxCard && box && box.occurence && handleBoxSelection?.(box.occurence.serialNumber)}>
        {status !== null && (
          <div className="absolute top-0 left-0 transform -translate-y-1/2 -mt-1 translate-x-4 max-w-full">
            <Label />
          </div>
        )}
        {isCSGEligible && !isIdentifyBoxCard && (
          <img
            src={'https://static.sky.co.nz/sky/account/box/csgStickerPurple.png'}
            alt={'Satisfaction Guarantee'}
            className="absolute top-0 right-0 w-8 h-auto"
            style={{ marginTop: '10px', marginRight: '10px' }}
          />
        )}
        {isIdentifyBoxCard && (
          <div className="absolute top-0 right-0" style={{ marginTop: '10px', marginRight: '10px' }}>
            {isBoxSelected ? <Checked /> : <Unchecked />}
          </div>
        )}
        <div className="w-full rounded-tl-md rounded-bl-none rounded-tr-md relative">
          <img className="w-full h-full block object-contain object-center" alt={product.name} src={product.custom1} />
          {hasFreeMySky && (
            <div className="absolute right-0 bottom-0 pr-2 pb-2">
              <MySkyOnUsBadge />
            </div>
          )}
        </div>
        <div className="flex flex-col p-7 sm:p-6 md:p-5 text-center h-full">
          <div>
            <p
              className={`sky-h5-black md:sky-h7-black ${
                isArrow(box?.occurence, product) ? 'purple-linear-gradient-text' : ''
              }`}>
              {/* Rename Sky Box to My Sky if it has recording or upgrading to. */}
              {formatBoxName(product, recording)}
            </p>
          </div>
          <div className={`w-full flex flex-col flex-1 items-center justify-end text-center`}>
            {box?.occurence?.serialNumber && (
              <div className="flex flex-row items-center content-center" style={{ marginTop: '10px' }}>
                <p className="flex max-w-full whitespace-no-wrap text-gray-darker sky-h7-bold">
                  <span className="inline-block truncate">
                    {box?.occurence?.nickName ? box?.occurence?.nickName : 'Add Nickname'}
                  </span>
                </p>
                {manageNicknameButton}
              </div>
            )}
            {box?.occurence?.serialNumber && (
              <p className="sky-h7-reg text-gray-darker min-w-32" style={{ marginTop: '10px' }}>
                {isNSB || isSkyPod ? 'Serial No. ' : 'Smartcard No. '}
                {box?.occurence?.serialNumber}
              </p>
            )}
            <span className="inline-flex flex-row items-center text-13px font-skyBold" style={{ marginTop: '20px' }}>
              {recording?.custom1 && boxHasRecording && (
                <img
                  className="w-full h-5 md:h-4 block object-contain object-center"
                  alt={product.name}
                  src={
                    isArrow(box?.occurence, product)
                      ? recording.custom1.replace('recording-1', 'recording-2')
                      : recording.custom1
                  }
                />
              )}
              {isSkyPod && product.custom10 && (
                <img
                  className="w-full h-5 md:h-4 block object-contain object-center"
                  alt={product.name}
                  src={product.custom10}
                />
              )}
            </span>
            {actionButton && <div style={{ marginTop: '20px' }}>{actionButton}</div>}
          </div>
        </div>
      </div>
    </>
  );
};

export { ICEBoxCard };
