import React, { useState, useEffect } from 'react';
import { T_Product, useBBname, useNewAcquisitionOrderStore } from '@sky-tv-group/shared';
import { Switch, TwoOptionsSelector, Pricing } from '@sky-tv-group/components';
import { YesNoValueType, SkyCustomerYesNoOption } from '../../types';

interface PackageCanBuyProps {
  data: T_Product;
  toggle: (prodcut: T_Product) => Promise<void>;
  showZeroAsFree?: boolean;
  toggleLabel?: string;
  hidePrice?: boolean;
  isAddonProduct?: boolean;
  recommended: boolean;
}

export let WifiBoosterPackageCanBuy = ({
  data,
  toggle,
  showZeroAsFree = false,
  toggleLabel,
  hidePrice = false,
  isAddonProduct = false,
  recommended = false,
}: PackageCanBuyProps) => {
  const { isUpdatingPlan, setIsUpdatingPlan } = useNewAcquisitionOrderStore();
  const bbName = useBBname();
  const [isBoosterRecommended, setIsBoosterRecommended] = useState(false);
  const [isBoosterAlreadyRecommended, setIsBoosterAlreadyRecommended] = useState(false);

  useEffect(() => {
    if (recommended) {
      setIsBoosterRecommended(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recommended]);

  const copy = {
    product: {
      title: 'Sky WiFi Booster',
      subtitle: "Extend your WiFi's range",
      titleInfo: 'If your home is multi-level or you live in a palace, then you may need an extra boost.',
      description: 'Do you already know you need a booster in your home?',
      toggleCaption: `${bbName.broadbandLightningFastWiFiBoost.label} Plan`,
      tip: 'One booster should do the trick, but if you find you need more coverage give us a call.',
      questionsTitle: 'Answer the questions below to see if you need a booster.',
      question1: 'Does your home have 2 or more levels?',
      question2: 'Does your home have 4+ bedrooms?',
      question3: 'Do you have 2 or more lounge rooms?',
      dontNeed: "We don't think you need a Sky WiFi Booster.",
      doNeed: 'Based on this we recommend a Sky WiFi Booster.',
      reminderBoldSection: '',
      reminderNormalSection: 'Just checking, as we do recommend a Sky WiFi Booster based on the specs of your home.',
    },
  };

  const {
    alreadyKnow,
    setAlreadyKnow,
    question1,
    setQuestion1,
    question2,
    setQuestion2,
    question3,
    setQuestion3,
  } = useNewAcquisitionOrderStore(s => ({
    alreadyKnow: s.persistedButtonStates.wifiBoosterAlreadyKnow,
    setAlreadyKnow: (value: YesNoValueType) => s.setPersistedButtonStates('wifiBoosterAlreadyKnow', value),
    question1: s.persistedButtonStates.wifiBoosterQuestion1,
    setQuestion1: (value: YesNoValueType) => s.setPersistedButtonStates('wifiBoosterQuestion1', value),
    question2: s.persistedButtonStates.wifiBoosterQuestion2,
    setQuestion2: (value: YesNoValueType) => s.setPersistedButtonStates('wifiBoosterQuestion2', value),
    question3: s.persistedButtonStates.wifiBoosterQuestion3,
    setQuestion3: (value: YesNoValueType) => s.setPersistedButtonStates('wifiBoosterQuestion3', value),
  }));

  const [showReminder, setShowReminder] = useState<boolean>(false);
  const [showTip, setShowTip] = useState<boolean>(false);
  const [isInitialRender, setIsInitialRender] = useState<boolean>(true);
  const [manualProductToggle, setManualProductToggle] = useState<boolean>(false);

  const needBooster =
    question1 === SkyCustomerYesNoOption.YES ||
    question2 === SkyCustomerYesNoOption.YES ||
    question3 === SkyCustomerYesNoOption.YES;

  let onToggle = async () => {
    setIsBoosterRecommended(false);
    setManualProductToggle(true);
    setIsUpdatingPlan(true);
    await toggle(data);
    setIsUpdatingPlan(false);
  };

  useEffect(() => {
    setShowTip(data?.quantityInCart > 0);
    if (isBoosterRecommended === true && isBoosterAlreadyRecommended === false) {
      setIsBoosterAlreadyRecommended(true);
      onToggle();
      return;
    }

    if (isBoosterRecommended === true && isBoosterAlreadyRecommended === false) {
      setIsBoosterAlreadyRecommended(true);
      onToggle();
      return;
    }

    if (isInitialRender) return;

    setShowReminder(
      (question1 === SkyCustomerYesNoOption.YES ||
        question2 === SkyCustomerYesNoOption.YES ||
        question3 === SkyCustomerYesNoOption.YES) &&
        data?.quantityInCart === 0
    );

    if (manualProductToggle) return;

    if (alreadyKnow === SkyCustomerYesNoOption.YES && data?.quantityInCart === 0) {
      onToggle();
    } else if (
      question1 === SkyCustomerYesNoOption.YES ||
      question2 === SkyCustomerYesNoOption.YES ||
      question3 === SkyCustomerYesNoOption.YES
    ) {
      if (data?.quantityInCart === 0) {
        onToggle();
      }
    } else if (
      question1 === SkyCustomerYesNoOption.NO ||
      question2 === SkyCustomerYesNoOption.NO ||
      question3 === SkyCustomerYesNoOption.NO
    ) {
      if (alreadyKnow !== SkyCustomerYesNoOption.YES && data?.quantityInCart > 0) {
        onToggle();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question1, question2, question3, alreadyKnow, data?.quantityInCart, isBoosterRecommended]);

  const onQuestion1Select = (value: YesNoValueType) => {
    setManualProductToggle(false);
    setIsInitialRender(false);
    setQuestion1(value);
  };

  const onQuestion2Select = (value: YesNoValueType) => {
    setManualProductToggle(false);
    setIsInitialRender(false);
    setQuestion2(value);
  };

  const onQuestion3Select = (value: YesNoValueType) => {
    setManualProductToggle(false);
    setIsInitialRender(false);
    setQuestion3(value);
  };

  const onAlreadyKnowSelect = (value: YesNoValueType) => {
    setManualProductToggle(false);
    setIsInitialRender(false);
    setAlreadyKnow(value);
  };

  return (
    <>
      <p className="sky-h6-bold md:sky-h6-bold p-6 text-blue-pure" id="booster-title">
        Does your WiFi need a boost?
      </p>
      <p className="px-6">{copy.product.titleInfo}</p>
      {/* MOBILE VIEW */}
      <div className="md:hidden border-2 rounded md:mx-0 mx-6">
        <div className="p-6">
          <div className="mb-8 pt-4 sky-h7-bold text-left">{copy.product.description}</div>
          <div className="flex justify-center pb-4 mt-6">
            <div style={{ width: '100%', maxWidth: '500px' }}>
              <TwoOptionsSelector
                valueSelected={alreadyKnow}
                valueLeft={SkyCustomerYesNoOption.YES}
                valueRight={SkyCustomerYesNoOption.NO}
                onSelectChange={onAlreadyKnowSelect}
                isDisabled={isUpdatingPlan}
              />
            </div>
          </div>
          {alreadyKnow === SkyCustomerYesNoOption.NO && (
            <div>
              <div className="mb-8 pt-4 sky-h7-bold text-left">{copy.product.questionsTitle}</div>
              <div className="sky-h7-reg text-left mb-4">{copy.product.question1}</div>
              <div className="flex justify-center">
                <div style={{ width: '100%', maxWidth: '500px' }}>
                  <TwoOptionsSelector
                    valueSelected={question1}
                    valueLeft={SkyCustomerYesNoOption.YES}
                    valueRight={SkyCustomerYesNoOption.NO}
                    onSelectChange={onQuestion1Select}
                    isDisabled={isUpdatingPlan}
                  />
                </div>
              </div>
              <div className="sky-h7-reg text-left mb-4 mt-6">{copy.product.question2}</div>
              <div className="flex justify-center">
                <div style={{ width: '100%', maxWidth: '500px' }}>
                  <TwoOptionsSelector
                    valueSelected={question2}
                    valueLeft={SkyCustomerYesNoOption.YES}
                    valueRight={SkyCustomerYesNoOption.NO}
                    onSelectChange={onQuestion2Select}
                    isDisabled={isUpdatingPlan}
                  />
                </div>
              </div>
              <div className="sky-h7-reg text-left mb-4 mt-6">{copy.product.question3}</div>
              <div className="flex justify-center">
                <div style={{ width: '100%', maxWidth: '500px' }}>
                  <TwoOptionsSelector
                    valueSelected={question3}
                    valueLeft={SkyCustomerYesNoOption.YES}
                    valueRight={SkyCustomerYesNoOption.NO}
                    onSelectChange={onQuestion3Select}
                    isDisabled={isUpdatingPlan}
                  />
                </div>
              </div>
              {needBooster && <div className="sky-h7-reg text-left mt-8 mb-4">{copy.product.doNeed}</div>}
              {!needBooster && <div className="sky-h7-reg text-left mt-8 mb-4">{copy.product.dontNeed}</div>}
            </div>
          )}

          <div className="flex justify-between pt-4 items-center">
            <div className="flex flex-col w-4/5">
              {toggleLabel && <div className="sky-h7-bold">{toggleLabel}</div>}
              {!hidePrice && <Pricing showZeroAsFree={showZeroAsFree} priceIncTax={data?.priceIncTax} />}
            </div>
            <div>
              <Switch
                value={data?.quantityInCart > 0}
                onChange={onToggle}
                disable={isUpdatingPlan}
                dataTestId={data?.sku}
              />
            </div>
          </div>

          {showReminder && (
            <div className="sky-h7-reg text-left text-red mt-8">
              <span className="sky-h7-bold">{copy.product.reminderBoldSection}</span>{' '}
              {copy.product.reminderNormalSection}
            </div>
          )}
        </div>
        {showTip && (
          <div className="sky-bg-gray-light p-3 mt-4 justify-center">
            <div className="sky-h7-bold text-center">{copy.product.tip}</div>
          </div>
        )}
      </div>
      {/* DESKTOP VIEW */}
      <div className="hidden md:block p-6">
        {/* main product view. e.g.: Router, Booster, StaticIP */}
        {!isAddonProduct && (
          <div>
            <div className="flex flex-col w-full py-6 xl:px-6 pl-6 border-2 rounded">
              <div className="w-full flex flex-row">
                <div className="sky-h6-reg text-left mb-6 w-full">{copy.product.description}</div>
                <div className="w-full">
                  <div style={{ width: '100%', maxWidth: '500px' }}>
                    <TwoOptionsSelector
                      valueSelected={alreadyKnow}
                      valueLeft={SkyCustomerYesNoOption.YES}
                      valueRight={SkyCustomerYesNoOption.NO}
                      onSelectChange={onAlreadyKnowSelect}
                      isDisabled={isUpdatingPlan}
                    />
                  </div>
                </div>
              </div>

              <div className="w-full flex flex-row">
                {alreadyKnow === SkyCustomerYesNoOption.NO && (
                  <div className="w-full">
                    <div className="my-10 sky-h5-bold text-center">{copy.product.questionsTitle}</div>

                    <div className="w-full flex flex-row my-4">
                      <div className="sky-h6-reg text-left w-full">{copy.product.question1}</div>
                      <div className="flex justify-center  w-full">
                        <div style={{ width: '100%', maxWidth: '500px' }}>
                          <TwoOptionsSelector
                            valueSelected={question1}
                            valueLeft={SkyCustomerYesNoOption.YES}
                            valueRight={SkyCustomerYesNoOption.NO}
                            onSelectChange={onQuestion1Select}
                            isDisabled={isUpdatingPlan}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="w-full flex flex-row my-4">
                      <div className="sky-h6-reg text-left w-full">{copy.product.question2}</div>
                      <div className="flex justify-center  w-full">
                        <div style={{ width: '100%', maxWidth: '500px' }}>
                          <TwoOptionsSelector
                            valueSelected={question2}
                            valueLeft={SkyCustomerYesNoOption.YES}
                            valueRight={SkyCustomerYesNoOption.NO}
                            onSelectChange={onQuestion2Select}
                            isDisabled={isUpdatingPlan}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="w-full flex flex-row my-4">
                      <div className="sky-h6-reg text-left w-full">{copy.product.question3}</div>
                      <div className="flex justify-center w-full">
                        <div style={{ width: '100%', maxWidth: '500px' }}>
                          <TwoOptionsSelector
                            valueSelected={question3}
                            valueLeft={SkyCustomerYesNoOption.YES}
                            valueRight={SkyCustomerYesNoOption.NO}
                            onSelectChange={onQuestion3Select}
                            isDisabled={isUpdatingPlan}
                          />
                        </div>
                      </div>
                    </div>

                    {needBooster && <div className="sky-h6-reg text-left my-10">{copy.product.doNeed}</div>}
                    {!needBooster && <div className="sky-h6-reg text-left my-10">{copy.product.dontNeed}</div>}
                  </div>
                )}
              </div>

              {/* TODO Convert this to the latest design. */}
              <div className="flex flex-row items-center justify-between w-full mt-10">
                {toggleLabel && <div className="sky-h5-bold">{toggleLabel}</div>}
                <div className="flex flex-row 2-1/4 items-center">
                  <div className="pr-4">
                    <Pricing
                      showZeroAsFree={showZeroAsFree}
                      priceIncTax={data?.priceIncTax}
                      priceClassName="sky-h6-reg"
                    />
                  </div>
                  <Switch
                    value={data?.quantityInCart > 0}
                    onChange={onToggle}
                    disable={isUpdatingPlan}
                    dataTestId={data?.sku}
                  />
                </div>
              </div>

              {showReminder && (
                <div className="sky-h6-reg text-left text-red mt-10">
                  <span className="sky-h6-bold">{copy.product.reminderBoldSection}</span>{' '}
                  {copy.product.reminderNormalSection}
                </div>
              )}
              {/* </div> */}
            </div>

            {showTip && (
              <div className="sky-bg-gray-light p-3 justify-center">
                <div className="sky-h7-bold text-center">{copy.product.tip}</div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
