import { AxiosInstance } from 'axios';
import { formatISO } from 'date-fns';
import parseISO from 'date-fns/parseISO';

import {
  TechLookupOrderType,
  TechLookupProductType,
  TechLookupTechType,
  TechnicianDateSlots,
  TechnicianSlot,
} from '../types';

export class TechnicianAvailabilityService {
  private orderServiceAgent: AxiosInstance;
  constructor(orderServiceAgent: AxiosInstance) {
    this.orderServiceAgent = orderServiceAgent;
  }

  public getTechnicianAvailability = async (
    techType: TechLookupTechType,
    fromDate: string,
    toDate: string,
    available: boolean,
    productType: TechLookupProductType,
    OrderId: string,
    tui: string,
    houseNumber: string,
    orderType: TechLookupOrderType,
    coreProductId: string,
    appointmentType: string,
    callCode: string | undefined
  ) => {
    const json = (
      await this.orderServiceAgent.get<TechnicianDateSlots[]>('/order/tech-lookup', {
        params: {
          techType,
          fromDate,
          toDate,
          available,
          productType,
          OrderId,
          tui,
          houseNumber,
          orderType,
          coreProductId,
          appointmentType,
          ...(callCode ? { callCode } : {}),
        },
      })
    ).data;

    //eventhough the type being serialized to has properties of type "Date"
    //these properties end up a strings in the ISO format
    //have to manually create date objects from those strings
    return json?.map(
      element =>
        ({
          date: parseISO(element.date.toString()),
          slots: element.slots.map(
            slot =>
              ({
                code: slot.code,
                displayName: slot.displayName,
                startTime: parseISO(slot.startTime.toString()),
                endTime: parseISO(slot.endTime.toString()),
              } as TechnicianSlot)
          ),
        } as TechnicianDateSlots)
    );
  };

  public bookTechnician = async (
    orderId: string,
    coreProductId: string,
    apptTypeKey: string,
    startTime: Date,
    endTime: Date
  ) =>
    (
      await this.orderServiceAgent.post(`/order/tech-book/${orderId}/${coreProductId}`, {
        appointmentTypeKey: apptTypeKey,
        appointmentTime: {
          startTime: formatISO(startTime),
          endTime: formatISO(endTime),
        },
      })
    ).data;
}
