import React from 'react';

export const SkyGoBanner = () => {
  return (
    <div className="w-full mb-24">
      <img
        className="w-full hidden md:block"
        src="https://static.sky.co.nz/sky/account/box/tvfooter-skygo-desktop.png"
        alt=""
      />
      <img
        className="w-full block md:hidden"
        src="https://static.sky.co.nz/sky/account/box/tvfooter-skygo-mobile.png"
        alt=""
      />
    </div>
  );
};
