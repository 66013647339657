import { T_Product, useCartContainer, useBroadband, useProductStore, productSkuIds } from '@sky-tv-group/shared';
import React from 'react';

import { CouponTypeValue } from '@sky-tv-group/shared';
import { konakartService, prequalService } from '../../services';
import { PackageBuy } from '../package/PackageBuy';

export const AdditionalServices = () => {
  const { products } = useProductStore();
  const { toggleProduct } = useCartContainer(konakartService, CouponTypeValue.Acquisition, false);
  const { broadbandJourney } = useBroadband(prequalService);

  const perfectInstall = products.find(p => p.sku === productSkuIds.broadbandPerfectInstall.primary);
  const staticIp = products.find(p => p.sku === productSkuIds.broadbandStaticIP.primary);

  const toggleAddon = async (product: T_Product) => {
    await toggleProduct(product, true, true, broadbandJourney);
  };

  const specialToggleLabel = "I'd like a static IP";

  return (
    <div id="addons" className="p-6 md:p-0 mb-8">
      <p className="sky-h6-bold md:sky-h6-bold p-6 text-blue-pure">Additional Services</p>
      {perfectInstall && (
        <PackageBuy
          key={perfectInstall?.id}
          data={perfectInstall}
          toggle={toggleAddon}
          showZeroAsFree={true}
          toggleLabel={'Super Set Up Technician'}
          period={'one off cost'}
        />
      )}
      {staticIp && (
        <PackageBuy
          key={staticIp?.id}
          data={staticIp}
          toggle={toggleProduct}
          showZeroAsFree={true}
          toggleLabel={specialToggleLabel}
        />
      )}
    </div>
  );
};
