import React, { useEffect, useReducer, useRef, useState } from 'react';
import { CheckIcon, HeyGoogleIcon } from './icon';
import { NextArrow, PrevArrow } from './carousel/DartCarouselArrow';
import { useSplitIO, SPLITIO_KEY } from '@sky-tv-group/shared';
import { SourceJourney } from './SkyBoxPrimary';
interface IBoxComparisonTable {
  showVodafoneTV?: boolean;
  showSkyPod?: boolean;
  showNewSkyBox?: boolean;
  source?: SourceJourney;
}

declare let ResizeObserver: any;

let checkIcon = (
  <div className="inline-block">
    <CheckIcon color="green" />{' '}
  </div>
);
let checkIconGreyedOut = (
  <div className="inline-block">
    <CheckIcon color="#E0E0E0" />{' '}
  </div>
);

let dotIcon = <div className="inline-block h-3 w-3  bg-gray-dark rounded-2xl"></div>;

const SuccessIcon = ({ fill }: { fill: string }) => {
  return (
    <>
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14 26C20.6274 26 26 20.6274 26 14C26 7.37258 20.6274 2 14 2C7.37258 2 2 7.37258 2 14C2 20.6274 7.37258 26 14 26ZM21.5 10C21.9015 10.3792 21.9196 11.0121 21.5404 11.4136L13.227 19.6866C13.0415 19.883 12.7844 19.996 12.5143 19.9999C12.2441 20.0038 11.9839 19.8981 11.7929 19.7071L6.79289 14.7071C6.40237 14.3166 6.40237 13.6834 6.79289 13.2929C7.18342 12.9024 7.81658 12.9024 8.20711 13.2929L12.4795 17.5653L20.0864 10.0404C20.4656 9.63887 21.0985 9.62079 21.5 10Z"
          fill={fill}
        />
      </svg>
    </>
  );
};
const FailureIcon = ({ fill }: { fill: string }) => {
  return (
    <>
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14 26C20.6274 26 26 20.6274 26 14C26 7.37258 20.6274 2 14 2C7.37258 2 2 7.37258 2 14C2 20.6274 7.37258 26 14 26ZM18.7071 10.7071C19.0976 10.3166 19.0976 9.68342 18.7071 9.29289C18.3166 8.90237 17.6834 8.90237 17.2929 9.29289L14 12.5858L10.7071 9.29289C10.3166 8.90237 9.68342 8.90237 9.2929 9.29289C8.90237 9.68342 8.90237 10.3166 9.2929 10.7071L12.5858 14L9.29289 17.2929C8.90237 17.6834 8.90237 18.3166 9.29289 18.7071C9.68342 19.0976 10.3166 19.0976 10.7071 18.7071L14 15.4142L17.2929 18.7071C17.6834 19.0976 18.3166 19.0976 18.7071 18.7071C19.0976 18.3166 19.0976 17.6834 18.7071 17.2929L15.4142 14L18.7071 10.7071Z"
          fill={fill}
        />
      </svg>
    </>
  );
};
export let BoxComparisonTable = ({ showVodafoneTV, showSkyPod, showNewSkyBox, source }: IBoxComparisonTable) => {
  const tableRef = useRef<HTMLDivElement>(null);

  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const currRef = tableRef?.current;
    function checkOverFlow() {
      if (currRef && currRef.offsetWidth < currRef.scrollWidth) {
        setIsOverflowing(true);
      } else {
        setIsOverflowing(false);
      }
    }
    checkOverFlow();
    window.addEventListener('resize', checkOverFlow);
  }, []);

  // used for hiding the sky box in the acquisition flow Comparison table.
  const [acqCmpTableSkyBoxHide] = useSplitIO(SPLITIO_KEY.SKYWEB_ACQ_HIDE_MNTH_ONEOF_CHRGE);

  const rectRef = useRef<HTMLDivElement>(null);
  const skyBoxRef = useRef<HTMLTableCellElement>(null)!;

  useEffect(() => {
    let observer: any = new ResizeObserver((entries: any) => {
      for (let entry of entries) {
        let element = entry.target as HTMLElement;
        if (element && rectRef.current) {
          rectRef.current!.style.left = element.offsetLeft + 'px';
          rectRef.current!.style.width = element.offsetWidth + 'px';
          rectRef.current!.style.height = tableRef.current?.offsetHeight + 'px';
        }
      }
    });
    if (rectRef.current) {
      observer.observe(skyBoxRef.current!);
    }
    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <div className="mx-4 md:mx-0 pb-12 relative">
      <div className="md:hidden  md:p-7 mb-4 md:mb-8 flex flex-row justify-between rounded-md text-midnight">
        <h3
          style={{
            background: 'linear-gradient(90deg, #0D1137 0%, #364D9E 51.76%, #812DCD 100%)',
            WebkitTextFillColor: 'transparent',
            WebkitBackgroundClip: 'text',
          }}
          className="sky-h4-black md:sky-h5-black">
          How do the Sky experiences compare?{' '}
        </h3>
      </div>
      <div className="h-auto  overflow-x-scroll md:overflow-auto rounded-lg md:h-auto" ref={tableRef}>
        <table className="w-full p-2 text-navy">
          <thead>
            <tr className="text-left font-bold sky-h6-reg" style={{ borderBottom: '1px solid #C5C5C5' }}>
              <th className="text-left font-black ">
                <h3 className="hidden md:inline-block nsb-background">
                  How do the Sky <br />
                  experiences compare?
                </h3>
              </th>
              {showVodafoneTV && <th className="md:p-5 text-center sky-text-gray-placeholder">VodafoneTV</th>}
              {acqCmpTableSkyBoxHide ? '' : <th className="md:p-5 text-center">Sky Box</th>}
              {showNewSkyBox && (
                <th className="md:p-5 text-center" ref={skyBoxRef}>
                  <div
                    className="absolute  rounded-lg"
                    ref={rectRef}
                    style={{
                      zIndex: -40,
                      background: 'linear-gradient(180.13deg, #0D1137 0.11%, #364D9E 51.51%, #812DCD 99.43%)',
                    }}></div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img
                      src="https://static.sky.co.nz/sky/box/arrow-box-16-9.png"
                      alt="new sky box"
                      className="md:w-40 md:h-20"
                    />
                    <p className="text-white text-sm md:text-lg"> New Sky Box</p>
                  </div>
                </th>
              )}
              {showSkyPod && (
                <th className="md:p-5 text-center">
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div>
                      {' '}
                      <img
                        src="https://static.sky.co.nz/sky/box/sky_pod_without_remote.png"
                        alt="sky Pod"
                        className="max-w-100px object-cover md:w-40 md:h-20"
                      />
                    </div>
                    <p className="text-sm md:text-lg"> Sky Pod</p>
                  </div>
                </th>
              )}
            </tr>
          </thead>
          <tbody className="text-13px">
            <tr style={{ borderBottom: '1px solid #C5C5C5' }}>
              <td className="py-3  text-left font-bold">
                Recording with My Sky <br /> <span className="font-normal text-sm">(additional charges apply)</span>
              </td>
              {showVodafoneTV && <td className="py-3  text-center">{dotIcon}</td>}
              {acqCmpTableSkyBoxHide ? '' : <td className="py-3  text-center">{checkIcon}</td>}
              {showNewSkyBox && (
                <td className="py-3  text-center">
                  <div className="flex flex-col items-center">
                    <SuccessIcon fill="#fff" />
                    <p className="text-white hidden md:inline-block text-sm">Record 5 shows at once with My Sky</p>{' '}
                    <p className="inline-block md:hidden text-white text-sm">5 shows at once</p>
                  </div>
                </td>
              )}
              {showSkyPod && (
                <td className="py-3  text-center">
                  <div className="flex justify-center">
                    <FailureIcon fill="#00B1EB" />
                  </div>
                </td>
              )}
            </tr>
            {(showNewSkyBox || showSkyPod) && (
              <>
                <tr style={{ borderBottom: '1px solid #C5C5C5' }}>
                  <td className="py-3  text-left font-bold">
                    <span className=" inline-block md:hidden lg:hidden">All in One</span>
                    <span className="hidden md:inline-block">Your favourites all in one place</span> <br />{' '}
                    <span className="font-normal text-sm">TV channels, streaming apps and Sky On Demand</span>
                  </td>
                  {showVodafoneTV && <td className="py-3  text-center">{checkIconGreyedOut}</td>}
                  {acqCmpTableSkyBoxHide ? '' : <td className="py-3  text-center">{dotIcon}</td>}
                  {showNewSkyBox && (
                    <td className="py-3  text-center">
                      <div className="flex justify-center">
                        <SuccessIcon fill="#fff" />
                      </div>
                    </td>
                  )}
                  {showSkyPod && (
                    <td className="py-3  text-center">
                      <div className="flex justify-center">
                        <SuccessIcon fill="#00B1EB" />
                      </div>
                    </td>
                  )}
                </tr>
                <tr style={{ borderBottom: '1px solid #C5C5C5' }}>
                  <td className="py-3  text-left font-bold ">
                    <span className="hidden md:inline-block">Home Page with Sky's recommended content</span>
                    <span className="inline-block md:hidden">Personalised Content</span>
                    <span className="inline-block md:hidden font-normal text-sm">
                      Homepage with recommended content
                    </span>
                  </td>
                  {showVodafoneTV && <td className="py-3  text-center">{dotIcon}</td>}
                  {acqCmpTableSkyBoxHide ? '' : <td className="py-3  text-center">{dotIcon}</td>}
                  {showNewSkyBox && (
                    <td className="py-3  text-center">
                      <div className="flex justify-center">
                        <SuccessIcon fill="#fff" />
                      </div>
                    </td>
                  )}
                  {showSkyPod && (
                    <td className="py-3  text-center">
                      <div className="flex justify-center">
                        <SuccessIcon fill="#00B1EB" />
                      </div>
                    </td>
                  )}
                </tr>
              </>
            )}
            <tr style={{ borderBottom: '1px solid #C5C5C5' }}>
              <td className="py-3 text-left font-bold">Satellite Dish Required </td>
              {showVodafoneTV && <td className="py-3  text-center">{checkIconGreyedOut}</td>}
              {acqCmpTableSkyBoxHide ? '' : <td className="py-3  text-center">{checkIcon}</td>}

              {showNewSkyBox && (
                <td className="py-3  text-center">
                  <div className="flex justify-center">
                    <SuccessIcon fill="#fff" />
                  </div>
                </td>
              )}
              {showSkyPod && (
                <td className="py-3  text-center">
                  <div className="flex justify-center">
                    <FailureIcon fill="#00B1EB" />
                  </div>
                </td>
              )}
            </tr>
            <tr style={{ borderBottom: '1px solid #C5C5C5' }}>
              <td className="py-3 text-left font-bold">Internet Connection Required</td>
              {showVodafoneTV && <td className="py-3  text-center">{'Included'}</td>}
              {acqCmpTableSkyBoxHide ? '' : <td className="py-3  text-center">{'500GB max'}</td>}
              {showNewSkyBox && (
                <td className="py-3  text-center">
                  <div className="flex justify-center">
                    <SuccessIcon fill="#fff" />
                  </div>
                </td>
              )}
              {showSkyPod && (
                <td className="py-3  text-center">
                  <div className="flex justify-center">
                    <SuccessIcon fill="#00B1EB" />
                  </div>
                </td>
              )}
            </tr>
            {(showNewSkyBox || showSkyPod) && (
              <>
                <tr style={{ borderBottom: '1px solid #C5C5C5' }}>
                  <td className="py-3 text-left font-bold">
                    Sky On Demand <br /> <span className="font-normal text-sm">(selected content)</span>
                  </td>
                  {showVodafoneTV && <td className="py-3  text-center">{checkIconGreyedOut}</td>}
                  {acqCmpTableSkyBoxHide ? '' : <td className="py-3  text-center">{dotIcon}</td>}

                  {showNewSkyBox && (
                    <td className="py-3  text-center">
                      <div className="flex flex-col items-center">
                        <SuccessIcon fill="#fff" />
                        <p className="text-white text-sm">Full Library</p>
                      </div>
                    </td>
                  )}
                  {showSkyPod && (
                    <td className="py-3  text-center">
                      <div className="flex flex-col items-center">
                        <SuccessIcon fill="#00B1EB" />
                        <p className="text-sm">Full Library</p>
                      </div>
                    </td>
                  )}
                </tr>
                <tr style={{ borderBottom: '1px solid #C5C5C5' }}>
                  <td className="py-3 text-left font-bold">
                    Scroll back 72 hours with Reverse EPG <br />{' '}
                    <span className="font-normal text-sm">(selected content)</span>
                  </td>
                  {showVodafoneTV && <td className="py-3  text-center">{dotIcon}</td>}
                  {acqCmpTableSkyBoxHide ? '' : <td className="py-3  text-center">{dotIcon}</td>}
                  {showNewSkyBox && (
                    <td className="py-3  text-center">
                      <div className="flex justify-center">
                        <FailureIcon fill="#fff" />
                      </div>
                    </td>
                  )}
                  {showSkyPod && (
                    <td className="py-3  text-center">
                      <div className="flex justify-center">
                        <SuccessIcon fill="#00B1EB" />
                      </div>
                    </td>
                  )}
                </tr>
                <tr style={{ borderBottom: '1px solid #C5C5C5' }}>
                  <td className="py-3 text-left font-bold">Watch from start</td>
                  {showVodafoneTV && <td className="py-3  text-center">{dotIcon}</td>}

                  {acqCmpTableSkyBoxHide ? '' : <td className="py-3   text-center">{dotIcon}</td>}
                  {showNewSkyBox && (
                    <td className="py-3   text-center">
                      <div className="flex justify-center">
                        <FailureIcon fill="#fff" />
                      </div>
                    </td>
                  )}
                  {showSkyPod && (
                    <td className="py-3   text-center">
                      <div className="flex justify-center">
                        <SuccessIcon fill="#00B1EB" />
                      </div>
                    </td>
                  )}
                </tr>
                <tr style={{ borderBottom: '1px solid #C5C5C5' }}>
                  <td className="py-3 text-left font-bold">4K-HDR Ready</td>
                  {showVodafoneTV && <td className="py-3   text-center">{dotIcon}</td>}
                  {acqCmpTableSkyBoxHide ? '' : <td className="py-3  text-center">{dotIcon}</td>}
                  {showNewSkyBox && (
                    <td className="py-3   text-center">
                      <div className="flex justify-center">
                        <SuccessIcon fill="#fff" />
                      </div>
                    </td>
                  )}
                  {showSkyPod && (
                    <td className="py-3  text-center">
                      <div className="flex justify-center">
                        <SuccessIcon fill="#00B1EB" />
                      </div>
                    </td>
                  )}
                </tr>
                <tr style={{ borderBottom: '1px solid #C5C5C5' }}>
                  <td className="py-3 text-left font-bold">Built In Chromecast</td>
                  {showVodafoneTV && <td className="py-3   pr-5  text-center">{dotIcon}</td>}
                  {acqCmpTableSkyBoxHide ? '' : <td className="py-3  text-center">{dotIcon}</td>}

                  {showNewSkyBox && (
                    <td className="py-3   text-center">
                      <div className="flex justify-center">
                        <SuccessIcon fill="#fff" />
                      </div>
                    </td>
                  )}
                  {showSkyPod && (
                    <td className="py-3  text-center">
                      <div className="flex justify-center">
                        <SuccessIcon fill="#00B1EB" />
                      </div>
                    </td>
                  )}
                </tr>
                <tr style={{ borderBottom: '1px solid #C5C5C5' }}>
                  <td className="py-3  text-left font-bold">Hey Google Voice Remote </td>
                  {showVodafoneTV && <td className="py-3   text-center">{checkIconGreyedOut}</td>}
                  {acqCmpTableSkyBoxHide ? '' : <td className="py-3  text-center">{dotIcon}</td>}

                  {showNewSkyBox && (
                    <td className="py-3   text-center">
                      <div className="flex justify-center">
                        <SuccessIcon fill="#fff" />
                      </div>
                    </td>
                  )}
                  {showSkyPod && (
                    <td className="py-3   text-center">
                      <div className="flex justify-center">
                        <SuccessIcon fill="#00B1EB" />
                      </div>
                    </td>
                  )}
                </tr>
                <tr style={{ borderBottom: '1px solid #C5C5C5' }}>
                  <td className="py-3 text-left font-bold">Individual user profiles</td>
                  {showVodafoneTV && <td className="py-3 pl-5 text-center">{checkIconGreyedOut}</td>}
                  {acqCmpTableSkyBoxHide ? '' : <td className="py-3   text-center">{dotIcon}</td>}

                  {showNewSkyBox && (
                    <td className="py-3   text-center">
                      <div className="flex justify-center">
                        <SuccessIcon fill="#fff" />
                      </div>
                    </td>
                  )}
                  {showSkyPod && (
                    <td className="py-3   text-center">
                      <div className="flex justify-center">
                        <SuccessIcon fill="#00B1EB" />
                      </div>
                    </td>
                  )}
                </tr>
              </>
            )}
            <tr style={{ borderBottom: '1px solid #C5C5C5' }}>
              <td className="py-3 text-left font-bold">Access to Sky Go companion App </td>
              {showVodafoneTV && <td className="py-3   text-center">{dotIcon}</td>}
              {acqCmpTableSkyBoxHide ? '' : <td className="py-3   text-center">{checkIcon}</td>}

              {showNewSkyBox && (
                <td className="py-3   text-center">
                  <div className="flex justify-center">
                    <SuccessIcon fill="#fff" />
                  </div>
                </td>
              )}
              {showSkyPod && (
                <td className="py-3   text-center">
                  <div className="flex justify-center">
                    <SuccessIcon fill="#00B1EB" />
                  </div>
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
