import React, { useEffect, useRef, useState } from 'react';
import { Field, useForm } from 'react-jeff';
import { FormElement, PropertyDetails, YourDetails, Button, Login } from '@sky-tv-group/components';
import {
  useAccountDetails,
  useDDAccountName,
  useDDAccountNumber,
  useDDBankName,
  useDDTC,
  useCCAccountNumber,
  useCCHolderName,
  useCCExpire,
  useCCCVC,
  useGiveCreditCheckPermission,
  useInstallationDetailsOwnershipField,
  usePropertyDetailsAddressField,
  usePropertyDetailsAddressSameAsInstallationAddressField,
  usePropertyDetailsBillingAddressField,
  usePropertyDetailsPaperlessBillingField,
  useYourDetailsDateOfBirthField,
  useYourDetailsEmailField,
  useYourDetailsFamilyNameField,
  useYourDetailsFirstNameField,
  useYourDetailsPhoneAreaCodeField,
  useYourDetailsPhoneTelephoneNumberField,
  useAnalytics,
  useOrderStore,
  useCouponStore,
  PACKAGE_TYPE,
  PAYMENT_METHOD,
  useProductInCart,
  CheckoutStates,
  usePropertyDetailServiceContactEmailAddressField,
  newAcquisitionOrderStoreApi,
  usePropertyDetailsDeliveryAddressField,
  usePropertyDetailsUseDifferentDeliveryAddressField,
  useAccountDetailsStore,
  orderStoreApi,
  boxesStoreApi,
  useNewAcquisitionOrderStore,
  useTotals,
  formatMoney,
} from '@sky-tv-group/shared';

import { next } from '../navigation';
import { addressService, konakartService, myAccountSWR, orderService } from '../../../services';
import history from '../../../history';
import { ACCOUNT_URL } from '../../../config';
import { PaymentInfo } from './PaymentInfo';
import { useAcquisitionJourneyState } from '../../../provider/AcquisitionJourney';
import { ArrowDelivery } from '../ArrowDelivery';
import { BackButton } from '../../../components/back-button';
import { BreadcrumbContainer } from '../../../components/BreadcrumbContainer';

const AccountForm = () => {
  const { paymentInfo,updateDeliveryAddress } = useAccountDetails(addressService);
  const { selectedAddressId, selectedInstallationAddress } = useNewAcquisitionOrderStore();
  // Account details
  const detailsFirstNameField = useYourDetailsFirstNameField();
  const detailsFamilyNameField = useYourDetailsFamilyNameField();
  const detailsEmailField = useYourDetailsEmailField();
  const detailsPhoneAreaCodeField = useYourDetailsPhoneAreaCodeField();
  const detailsPhoneTelephoneNumberField = useYourDetailsPhoneTelephoneNumberField();
  const detailsDateOfBirthField = useYourDetailsDateOfBirthField();
  // Property details
  const propertyDetailsAddressField = usePropertyDetailsAddressField();
  const propertyDetailsPaperlessBillingField = usePropertyDetailsPaperlessBillingField();
  const propertyDetailsAddressSameAsInstallationAddressField = usePropertyDetailsAddressSameAsInstallationAddressField();
  const propertyDetailsBillingAddressField = usePropertyDetailsBillingAddressField();
  const propertyDetailsOwnershipField = useInstallationDetailsOwnershipField();
  const serviceEmailField = usePropertyDetailServiceContactEmailAddressField();
  const propertyDetailsDeliveryAddressField = usePropertyDetailsDeliveryAddressField();
  const useDifferentDeliveryAddressField = usePropertyDetailsUseDifferentDeliveryAddressField();
  // Checkbox fields - might need to
  const creditCheckCheckbox = useGiveCreditCheckPermission();

  // payment info
  const totals = useTotals(myAccountSWR);

  const ddAccountNumber = useDDAccountNumber();
  const ddBankName = useDDBankName();
  const ddAccountName = useDDAccountName();
  const ddTC = useDDTC();

  const ccAccountNumber = useCCAccountNumber();
  const ccCVC = useCCCVC();
  const ccExpire = useCCExpire();
  const ccHolderNme = useCCHolderName();

  const { progressBar } = useAcquisitionJourneyState();
  const { checkoutStepCompleted, identifyAnonymousUser } = useAnalytics();
  const order = useOrderStore(s => s.order);
  const { coupons } = useCouponStore();
  const { accountDetails } = useAccountDetailsStore();
  const { broadbandPhoneProducts, requireDeliveryOnly } = useProductInCart();
  const [isConsentChecked, setConsentChecked] = useState(accountDetails.yourDetail.emailConsent);
  const [broadbandProductInCart, setbroadbandInCart] = useState(false);
  const [showDetails, setShowDetails] = useState(true);
  const [showpropertyDetails, setShowPropertyDetails] = useState(false);
  const [showpaymentInfo, setShowPaymentInfo] = useState(false);
  const [showDetailsCheck, setShowDetailsCheck] = useState(false);
  const [showPropertyDetailsCheck, setShowPropertyDetailsCheck] = useState(false);

  let stickyRef = useRef<HTMLDivElement>(null) as any;
  function isValiEmail(val: string) {
    let regEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    return regEmail.test(val);
  }

  const propertyDetailsRef = useRef<HTMLDivElement>(null);
  const paymentDetailsRef = useRef<HTMLDivElement>(null);


  function handleStickyBarScroll() {
    let footerElement = document.querySelector('#footerTermsAndCond') as any;
    if (stickyRef.current) {
      if (window.scrollY >= document.body.scrollHeight - window.innerHeight) {
        footerElement.style.marginBottom = stickyRef.current.offsetHeight + 'px';
      } else {
        footerElement.style.marginBottom = '0px';
      }
    }
  }
    const postData = async () => {
      try {
        const response = await orderService.postAbandonedCart(
          accountDetails.yourDetail.email,
          orderStoreApi?.getState().order,
          boxesStoreApi.getState().boxes,
          coupons,
          accountDetails.yourDetail,
          accountDetails.propertyDetail,
          selectedAddressId ? newAcquisitionOrderStoreApi.getState() : undefined,
          'getsky'
        );
      } catch (e) {
        console.log('error' + e);
      }
    };
    const postDatawithEmptyPayload = async () => {
      try {
        const response = await orderService.postAbandonedCart(
          accountDetails.yourDetail.email,
          undefined,
          [],
          undefined
        );
      } catch (e) {
        console.log('error' + e);
      }
    };


  const handleApicall = (value: boolean) => {
    setConsentChecked(value);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleStickyBarScroll);
    return () => {
      window.removeEventListener('scroll', handleStickyBarScroll);
    };
  }, []);
  useEffect(() => {
    if (broadbandPhoneProducts!.length > 0) {
      setbroadbandInCart(true);
    }
  }, [broadbandPhoneProducts]);

  useEffect(() => {
    if(showDetails){
      setShowPropertyDetails(false)
      setShowPaymentInfo(false)
    }
    else if(showpropertyDetails){
      setShowDetails(false)
      setShowPaymentInfo(false)
    }
    else if(showpaymentInfo){
      setShowDetails(false)
      setShowPropertyDetails(false)
    }
  },[showDetails,showpropertyDetails,showpaymentInfo])

  const packageType = broadbandProductInCart ? PACKAGE_TYPE.DTH_BROADBAND : PACKAGE_TYPE.DTH;

  const fields: Array<Field<any, any>> = [
    detailsFirstNameField,
    detailsFamilyNameField,
    detailsEmailField,
    detailsPhoneAreaCodeField,
    detailsPhoneTelephoneNumberField,
    detailsDateOfBirthField,
    propertyDetailsAddressField,
    propertyDetailsPaperlessBillingField,
    propertyDetailsAddressSameAsInstallationAddressField,
    propertyDetailsBillingAddressField,
    serviceEmailField,
    propertyDetailsOwnershipField,
  ];

  if (paymentInfo.isDD) {
    fields.push(ddAccountNumber);
    fields.push(ddBankName);
    fields.push(ddAccountName);
    fields.push(ddTC);
  } else {
    // fields.push(ccAccountNumber);
    // fields.push(ccCVC);
    // fields.push(ccExpire);
    // fields.push(ccHolderNme);
  }

  if (requireDeliveryOnly) {
    fields.push(propertyDetailsDeliveryAddressField);
    fields.push(useDifferentDeliveryAddressField);
  }

  useEffect(() => {
    if(requireDeliveryOnly){
    useDifferentDeliveryAddressField.setValue(false)
    updateDeliveryAddress(propertyDetailsAddressField?.value);
    }
    else{
      updateDeliveryAddress(null);
    }
    },[propertyDetailsAddressField?.value])

  const form = useForm({
    fields,
    onSubmit: () => {},
  });

  useEffect(() => {
    const {
      selectedAddressId,
      selectedInstallationAddress,
      selectedTuiAddress,
    } = newAcquisitionOrderStoreApi.getState();

    if (selectedAddressId && selectedInstallationAddress && selectedTuiAddress) {
      const address = {
        id: selectedAddressId,
        label: selectedInstallationAddress,
        tui: selectedTuiAddress,
      };
      propertyDetailsAddressField.setValue(address);
      propertyDetailsAddressField.setDisabled(true);
    }

    // need to pick up initial value from sessionstorage once feature is ready
    creditCheckCheckbox.setValue(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    updateDeliveryAddress(null);
  }, []);

  const goBack = () => history.push('/build-my-plan/checkout');
  const isButtonVisible: boolean = form.valid && !showDetails && !showpropertyDetails && showpaymentInfo;
  const goNext = async () => {
    form.validate();
    if (form.valid) {
      /* Identify anonymous user for Analytics. */
      identifyAnonymousUser(detailsEmailField.value, detailsFirstNameField.value, detailsDateOfBirthField.value);

      if (paymentInfo.isDD) {
        if (ddAccountNumber.value.join('').length > 0) {
          checkoutStepCompleted(false, order!, PAYMENT_METHOD.DEBIT, 1, coupons, packageType);
          next(CheckoutStates.DETAILS);
        }
      } else {
        checkoutStepCompleted(false, order!, PAYMENT_METHOD.CREDIT, 1, coupons, packageType);
        next(CheckoutStates.DETAILS);
      }
    }
  };
  useEffect(() => {
    if (detailsEmailField.value) {
      serviceEmailField.setValue(detailsEmailField.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailsEmailField.value]);

  useEffect(() => {
    if (
      detailsFirstNameField.value &&
      detailsFamilyNameField?.value &&
      detailsPhoneAreaCodeField?.value &&
      detailsPhoneTelephoneNumberField?.value &&
      detailsEmailField?.value &&
      detailsDateOfBirthField?.value
    ) {
      setShowDetailsCheck(true);
    } else if (
      !detailsFirstNameField?.validate ||
      !detailsFamilyNameField?.value ||
      !detailsPhoneAreaCodeField?.value ||
      !detailsPhoneTelephoneNumberField?.value ||
      !detailsEmailField?.value ||
      !detailsDateOfBirthField?.value
    ) {
      setShowDetailsCheck(false);
    }
  }, [
    detailsFirstNameField.value,
    detailsFamilyNameField.value,
    detailsEmailField.value,
    detailsPhoneAreaCodeField.value,
    detailsPhoneTelephoneNumberField.value,
    detailsDateOfBirthField.value,
    setShowDetails,
  ]);

  useEffect(() => {
    if (propertyDetailsAddressField.value && propertyDetailsOwnershipField?.value && serviceEmailField?.value) {
      setShowPropertyDetailsCheck(true);
    } else if (
      !propertyDetailsAddressField?.validate ||
      !propertyDetailsOwnershipField?.value ||
      !serviceEmailField?.value
    ) {
      setShowPropertyDetailsCheck(false);
    }
  }, [
    propertyDetailsAddressField.value,
    propertyDetailsOwnershipField.value,
    serviceEmailField.value,
    setShowPropertyDetails,
  ]);

  const handlePropertyDetails = () => {
    if (
      detailsFirstNameField.value &&
      detailsFamilyNameField.value &&
      detailsPhoneAreaCodeField.value &&
      detailsPhoneTelephoneNumberField.value &&
      detailsEmailField.value &&
      detailsDateOfBirthField.value
    ) {
      setShowPropertyDetails(true);
      setShowDetails(false);
      propertyDetailsRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    // trigger abandon api call
    if (isConsentChecked && isValiEmail(accountDetails.yourDetail.email) && detailsFirstNameField?.value && detailsFamilyNameField?.value && detailsDateOfBirthField?.value && detailsPhoneAreaCodeField?.value && detailsPhoneTelephoneNumberField?.value ) {
      postData();
    }
    if (!isConsentChecked && isValiEmail(accountDetails.yourDetail.email) && detailsFirstNameField?.value && detailsFamilyNameField?.value && detailsDateOfBirthField?.value && detailsPhoneAreaCodeField?.value && detailsPhoneTelephoneNumberField?.value )
      {
        //commented to stop API call with empty payload on click of chkbox(Abandoned cart)
        // postDatawithEmptyPayload();
      }
  }


  const handlePaymentDetails = () => {
    if (
      propertyDetailsAddressField?.value &&
      propertyDetailsOwnershipField?.value &&
      serviceEmailField.value &&
      showDetailsCheck &&
      showPropertyDetailsCheck
    ) {
      setShowPropertyDetails(false);
      setShowDetails(false);
      setShowPaymentInfo(true);
      paymentDetailsRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <div>
      <BreadcrumbContainer progressBarStatus={false}/>
      <div className="container m-auto md:pb-10 lg:mt-16 lg:px-6 mt-4 text-navy" data-testid="account-form">
        <div className="flex flex-col justify-center items-center py-4 md:w-100 md:justify-start md:items-start">
          <BackButton buttontext="Go back to Review" onClick={() => history.push('/build-my-plan/checkout')} />
        </div>
        <FormElement {...form.props}>
          <YourDetails
            titleText="About You"
            formValidation={form}
            firstNameField={detailsFirstNameField}
            familyNameField={detailsFamilyNameField}
            emailField={detailsEmailField}
            phoneAreaCodeField={detailsPhoneAreaCodeField}
            phoneTelephoneNumberField={detailsPhoneTelephoneNumberField}
            dateOfBirthField={detailsDateOfBirthField}
            addressService={addressService}
            emailConsentField={true}
            handleApicall={handleApicall}
            isAbandon={true}
            showDetails={showDetails}
            setShowDetails={setShowDetails}
            showDetailsCheck={showDetailsCheck}
            handlePropertyDetails={handlePropertyDetails}
          />
          <div ref={propertyDetailsRef}>
            <PropertyDetails
              titleText="About Your Property"
              formValidation={form}
              addressField={propertyDetailsAddressField}
              paperlessBillingField={propertyDetailsPaperlessBillingField}
              addressSameAsInstallationAddressField={propertyDetailsAddressSameAsInstallationAddressField}
              billingAddressField={propertyDetailsBillingAddressField}
              serviceEmailField={serviceEmailField}
              ownershipField={propertyDetailsOwnershipField}
              addressService={addressService}
              konakartService={konakartService}
              disableAddressField={propertyDetailsAddressField.disabled}
              showpropertyDetails={showpropertyDetails}
              isAbandon={true}
              handlePaymentDetails={handlePaymentDetails}
              showPropertyDetailsCheck={showPropertyDetailsCheck}
              setShowPropertyDetails={setShowPropertyDetails}
              showDetails={showDetails}
              showPaymentInfo ={showpaymentInfo}
            />
          </div>
          {/* {requireDeliveryOnly && (
            <ArrowDelivery
              formValidation={form}
              addressField={propertyDetailsAddressField}
              deliveryAddressField={propertyDetailsDeliveryAddressField}
              useDifferentDeliveryAddressField={useDifferentDeliveryAddressField}
            />
          )} */}
          <div ref={paymentDetailsRef} style={{scrollMarginTop:"-60px"}}>
            <PaymentInfo
              formValidation={form}
              ddAccountNumber={ddAccountNumber}
              ddBankName={ddBankName}
              ddAccountName={ddAccountName}
              ddTC={ddTC}
              ccAccountNumber={ccAccountNumber}
              ccCVC={ccCVC}
              ccExpire={ccExpire}
              ccHolderNme={ccHolderNme}
              isAbandon={true}
              showpaymentInfo={showpaymentInfo}
              setShowPaymentInfo={setShowPaymentInfo}
              showDetails= {showDetails}
              showPropertyDetails = {showpropertyDetails}
            />
            </div>
            {/* first bill section */}
            <div className="flex flex-col mx-2 w-full border-b border-t py-3 border-gray-300">
              <div className="flex flex-col md:flex-row bill-styling mt-4">
                <p className="mr-4">
                  Your{' '}
                  <span className="font-bold">
                    <strong>First Bill:</strong>
                  </span>
                </p>
                <span>
                  <strong>{formatMoney(totals.priceYouPayToday)}</strong>
                </span>
              </div>
              <div className="sky-h7-reg md:sky-sub sky-text-midnight mt-2">
                This includes any one-off charges your subscription may have.{' '}
              </div>
              <div className="mt-4 rounded-md border-l-6 border-blue-light bg-blue-cool inline-block px-40px py-4 sky-h6-reg mb-8">
                Please note, charges to your account will not be placed until your first billing cycle begins once you
                place your order.
              </div>
            </div>


          {/* <CreditCheckCheckbox creditCheckCheckbox={creditCheckCheckbox} /> */}
          <div className=" rounded-lg  overflow-hidden text-white my-12">
            {/* TODO T&Cs here? */}
            <div
              className={`${showDetails || showpropertyDetails || !showpaymentInfo ? 'hidden md:block' : 'block '} fixed
               min-w-full bottom-0  z-50 md:static lg:static left-0`}
              ref={stickyRef}>
              <div
                className={`flex justify-end md:p-12 lg:p-12  min-w-full bottom-0 z-50 lg:shadow-none md:shadow-none lg:rounded-none md:rounded-none sticky-custom bg-white left-0 p-5`}>
                {/* <Button
                  data-testid="back-to-build-my-plan-checkout-button"
                  colorMode="light"
                  variant="secondary"
                  onClick={goBack}
                  disabled={false}
                  className="hidden lg:block md:block">
                  Back
                </Button> */}
                <Button
                  data-testid="next-to-confirm-button"
                  variant="primary"
                  onClick={goNext}
                  colorMode={isButtonVisible ? 'pure' : 'disabled'}
                  className="min-w-full min-w-fit">
                  Next: Installation
                </Button>
              </div>
            </div>
          </div>
        </FormElement>
      </div>
    </div>
  );
};

export { AccountForm };
