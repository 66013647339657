import React from 'react';
import { getProductImageUrl, T_Product, useNewAcquisitionOrderStore } from '@sky-tv-group/shared';
import { Aspect, Switch, Image } from '@sky-tv-group/components';

import { getProductOrangeImage } from '../../helper';
import { IcePricing } from '../IcePricing';

interface PackageBuyProps {
  data: T_Product;
  toggle: (prodcut: T_Product) => Promise<void>;
  overrideImageUrl?: string;
  showZeroAsFree?: boolean;
  toggleLabel?: string;
  hidePrice?: boolean;
  period?: string;
}

export let PackageBuy = ({
  data,
  toggle,
  overrideImageUrl,
  showZeroAsFree = false,
  toggleLabel,
  hidePrice = false,
  period = '/mth',
}: PackageBuyProps) => {
  const { isUpdatingPlan, setIsUpdatingPlan } = useNewAcquisitionOrderStore();
  let onToggle = async () => {
    setIsUpdatingPlan(true);
    await toggle(data);
    setIsUpdatingPlan(false);
  };

  const productImg = getProductOrangeImage(data?.sku) ?? getProductImageUrl(data);

  return (
    <div className="md:p-6" data-testid={data?.name}>
      <div className="flex flex-col xl:flex-row">
        <div className="px-6 xl:pl-0 flex-1">
          <div
            className={`${
              overrideImageUrl ? 'sky-dark-blue-gradient' : 'bg-gray-cool'
            }  w-full flex items-center justify-center rounded-md`}>
            <Aspect ratio={7 / 16}>
              <div className="w-full h-full flex flex-col items-center justify-center">
                <Image className="w-32 object-cover" src={overrideImageUrl ?? productImg} alt={data?.description} />
                <div className="flex flex-col">
                  <div className={` sky-h5-bold ${overrideImageUrl ? 'text-white' : 'text-navy'}`}>{data?.name}</div>
                </div>
              </div>
            </Aspect>
          </div>
        </div>
        <div className="flex flex-col justify-evenly px-6 flex-1">
          <div className="xl:hidden sky-h6-reg text-black w-3/4 mt-5">{data?.description}</div>
          <div className="hidden xl:block sky-h6-reg w-3/4">{data?.description}</div>
          <div className="flex flex-row items-center justify-between w-full mt-4">
            {toggleLabel && <div className="sky-h5-bold">{toggleLabel}</div>}
            <div className="flex flex-row items-center">
              <div className="pr-4">
                {!hidePrice && <IcePricing price={data?.priceIncTax} showZeroAsFree={showZeroAsFree} period={period} />}
              </div>
              <Switch
                value={data?.quantityInCart > 0}
                onChange={onToggle}
                disable={isUpdatingPlan}
                dataTestId={data?.sku}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
