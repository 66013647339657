import React from 'react';
import { useState, useEffect } from 'react';
import { Button } from './button';
import { GetOrderConfirmationQuery } from '@sky-tv-group/graphql';
import { getStyledText, useSplitIO, SPLITIO_KEY, OrderService } from '@sky-tv-group/shared';
import './style.css';
interface Props {
  email?: string;
  firstName?: string;
  accountNumber?: string;
  data?: GetOrderConfirmationQuery;
  orderService?: OrderService;
}

const ConfirmationScreen = ({ firstName, accountNumber, email, data, orderService }: Props) => {
  const timer = 60;
  const [countdown, setCountdown] = useState(timer);
  const [emailError, setEmailError] = useState('');
  const [verifyAccountFlag] = useSplitIO(SPLITIO_KEY.SKYWEB_ORD_CNFRM_MAIL_BLOCK);
  const replaceItems = (node: string | null, item1: string[], item2: any) => {
    if (node) {
      node = node?.replace(`{{${item1[0]}}}`, item2[0]);
      node = node?.replace(`{{${item1[1]}}}`, item2[1]);
      return node;
    }
  };

  useEffect(() => {
    let timer: undefined | NodeJS.Timer;
    if (accountNumber && countdown > 0) {
      timer = setInterval(() => setCountdown(countdown - 1), 1000);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  if (data?.orderConfirmation) {
    let resultdata: Record<string, any> = {};
    data.orderConfirmation?.forEach(object => {
      switch (object?.category) {
        case 'header':
          resultdata[object?.category] = {
            header: replaceItems(object?.header, ['name'], [firstName!]),
            description: getStyledText(
              replaceItems(object?.description, ['email', 'AccountNumber'], [email!, accountNumber]),
              'text-blue-light font-bold'
            ),
            skylogo: object?.iconSrc,
            skylogoMobile: object?.iconMobileSrc,
          };
          break;
        case 'VerifyAccount':
          resultdata[object?.category] = {
            image: object?.iconSrc,
            imageDescription: object?.category,
            header: object?.header,
            description: getStyledText(replaceItems(object?.description, ['email'], [email!]), ''),
            btnText: object?.buttonCtaName,
          };
          break;
        case 'skyGo':
          resultdata[object?.category] = {
            skyGo: object?.iconSrc,
            skyGoMobile: object?.iconMobileSrc,
            header: object?.header,
            description: object?.description,
            buttonCtaName: object?.buttonCtaName,
            buttonLink: object?.buttonLink,
            appLogoLargeSrc: object?.appLogoLargeSrc,
            appLogoSmallSrc: object?.appLogoSmallSrc,
          };
          break;
        case 'skyMyAccountApp':
          resultdata[object?.category] = {
            skyapp: object?.iconSrc,
            skyappMobile: object?.iconMobileSrc,
            header: object?.header,
            description: object?.description,
            buttonCtaName: object?.buttonCtaName,
            buttonLink: object?.buttonLink,
            appLogoLargeSrc: object?.appLogoLargeSrc,
            appLogoSmallSrc: object?.appLogoSmallSrc,
          };
          break;
        default:
          break;
      }
    });
    const handleClick = (name: string) => {
      if (name === resultdata?.skyGo?.header) {
        window.location.href = resultdata?.skyGo?.buttonLink;
      } else window.location.href = resultdata?.skyMyAccountApp?.buttonLink;
    };

    const handleResendVerificationClick = async () => {
      setCountdown(timer);
      setEmailError('');
      try {
        const emailVerificationResponse: any = await orderService?.postReSendVerificationMail(accountNumber!, email!);
        if (emailVerificationResponse.code !== 'OK') {
          setEmailError(emailVerificationResponse.message);
        }
      } catch (error) {
        console.log(error);
        setEmailError('Unable to send verification mail');
      }
    };

    return (
      <>
        <div className="mt-6 md:mt-10 text-center container-style">
          <img
            src={resultdata?.header?.skylogo?.url}
            style={{ margin: 'auto' }}
            alt={resultdata?.header?.skylogo?.title}
            className="responsive-image desktop"
          />
          <img
            src={resultdata?.header?.skylogoMobile?.url}
            alt={resultdata?.header?.skylogoMobile?.title}
            style={{ margin: 'auto' }}
            className="responsive-image mobile"
          />
          <div className="mt-4   header-style font-black mb-4">{resultdata?.header?.header}</div>
          {accountNumber && (
            <div style={{ fontWeight: 450, whiteSpace: 'pre-line' }} className=" header-description text-xl leading-6">
              <div dangerouslySetInnerHTML={{ __html: resultdata?.header?.description }} />
            </div>
          )}
          <div className="my-10 responsive-image desktop">
            <hr
              className="  responsive-image desktop"
              style={{ border: '1px solid #c5c5c5', margin: 'auto', maxWidth: '470px' }}></hr>
          </div>
          <div style={{ height: 80, position: 'relative' }} className="responsive-image mobile flex">
            <img
              style={{ position: 'absolute' }}
              className=" "
              src="https://static.sky.co.nz/sky/icons/line-divider-down.png"
              alt=""
            />
          </div>
        </div>
        {accountNumber && resultdata.VerifyAccount && verifyAccountFlag && (
          <div>
            <div className="flex  flex-col  ">
              <img
                style={{
                  height: '100px',
                  minWidth: '100px',
                  objectFit: 'contain',
                }}
                src={resultdata.VerifyAccount?.image?.url}
                alt=""
              />

              <div className=" text-center ">
                <h1 className="mt-4 text-center header-style font-black mb-4">{resultdata?.VerifyAccount?.header}</h1>
                <div
                  className="text-xl leading-6 mb-6  "
                  style={{ whiteSpace: 'pre-line', fontWeight: 450 }}
                  dangerouslySetInnerHTML={{ __html: resultdata.VerifyAccount?.description }}
                />
                {resultdata.VerifyAccount?.btnText && !emailError && (
                  <>
                    <Button
                      variant="primary"
                      className={'w-280px'}
                      colorMode={countdown > 0 ? 'disabled' : 'pure'}
                      onClick={() => handleResendVerificationClick()}
                      disabled={countdown > 0}>
                      {resultdata.VerifyAccount?.btnText}
                    </Button>
                    {countdown > 0 && (
                      <p className="text-error">You can resend the verification email in {countdown} seconds.</p>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="my-10">
              <hr
                className=" responsive-image desktop"
                style={{ border: '1px solid #c5c5c5', margin: 'auto', maxWidth: '470px' }}></hr>
            </div>
          </div>
        )}

        <div className="flex   md:mt-10 mb-14  sky-app ">
          <div className="mr-5 flex flex-1 flex-col md:border-grey-c5 items-center ">
            <img
              style={{ width: 'auto', objectFit: 'contain' }}
              className="flex-1 max-580"
              srcSet={`${resultdata?.skyGo?.skyGo?.url} 1024w,${resultdata?.skyGo?.skyGoMobile?.url} 480w`}
              sizes="(min-width:768px) 1024px,480px"
              alt={resultdata?.skyGo?.skyGo?.title}
            />
            <div className="mt-4 text-center header-style font-black mb-4">{resultdata?.skyGo?.header}</div>
            <div className="text-center text-xl leading-6">{resultdata?.skyGo?.description}</div>
            <div className="text-center mt-6 mb-6 ">
              <Button
                variant="primary"
                className="sky-button--primary-pure w-280px"
                onClick={() => handleClick(resultdata?.skyGo?.header)}>
                {resultdata?.skyGo?.buttonCtaName}
              </Button>
            </div>
            <img
              src={resultdata?.skyGo?.appLogoSmallSrc?.url}
              alt={resultdata?.skyGo?.appLogoSmallSrc?.title}
              className="responsive-image mobile"
            />
            <img
              src={resultdata?.skyGo?.appLogoLargeSrc?.url}
              alt={resultdata?.skyGo?.appLogoLargeSrc?.title}
              className="responsive-image desktop"
            />
          </div>
          <div className="line "></div>
          <div className="ml-5 flex flex-1 flex-col items-center">
            <img
              alt={resultdata?.skyMyAccountApp?.skyapp?.title}
              srcSet={`${resultdata?.skyMyAccountApp?.skyapp?.url} 1024w,${resultdata?.skyMyAccountApp?.skyappMobile?.url} 480w`}
              sizes="(min-width:768px) 1024px,480px"
              className="flex-1 max-580"
              style={{ width: 'auto', objectFit: 'contain' }}
            />

            <div className="mt-4 text-center  header-style font-black mb-4">{resultdata?.skyMyAccountApp?.header}</div>
            <div style={{ minHeight: '70px' }} className="text-center text-xl leading-6 ">
              {resultdata?.skyMyAccountApp?.description}
            </div>
            <div className="text-center mt-6 mb-6 ">
              <Button
                variant="primary"
                className="sky-button--primary-pure w-280px"
                onClick={() => handleClick(resultdata?.skyMyAccountApp?.header)}>
                {resultdata?.skyMyAccountApp?.buttonCtaName}
              </Button>
            </div>
            <img
              src={resultdata?.skyMyAccountApp?.appLogoSmallSrc?.url}
              alt={resultdata?.skyMyAccountApp?.appLogoSmallSrc?.title}
              className="responsive-image mobile"
            />
            <img
              src={resultdata?.skyMyAccountApp?.appLogoLargeSrc?.url}
              alt={resultdata?.skyMyAccountApp?.appLogoLargeSrc?.title}
              className="responsive-image desktop"
            />
          </div>
        </div>
      </>
    );
  } else return null;
};
export { ConfirmationScreen };
