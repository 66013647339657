import * as React from 'react';
import VideoPlayer from './video-player';
import debounce from 'lodash.debounce';
import { Record, NewSkyBox, Home, Watch, Google } from './svg-assets';

const ArrowRoiCarousel = () => {
  const features = [
    {
      icon: NewSkyBox,
      headline: 'All in one.',
      description: 'The best of Sky Satellite TV, On Demand <br/>and streaming apps.',
      background: 'arrow-sky_0',
    },
    {
      icon: Record,
      headline: 'Record more.',
      description: 'With MySky you can now record up to 5 shows while watching another live on a 1TB Hard Drive.',
      background: 'arrow-sky_1',
    },
    {
      icon: Google,
      headline: 'All new voice remote.',
      description:
        'Use your voice to search what’s on now, go to home screen and more. Just ask Google.. <br/>Just ask Google',
      background: 'arrow-sky_3',
    },
    {
      icon: Home,
      headline: 'Home reimagined.',
      description: 'It’s the new way to watch and stream with Sky TV and your favourite apps with one remote. ',
      background: 'arrow-sky_2',
    },
    {
      icon: Watch,
      headline: 'Watch on any screen.',
      description:
        'Watch popular Sky channels and on demand TV on the big screen or any PC, Mac, tablet or phone with Sky Go.',
      background: 'arrow-sky_4',
    },
  ] as any;

  const activateFeature = (e: any) => {
    const showDescription = e.target.parentNode.childNodes[3];
    e.target.parentNode.classList.toggle('roi-open');
    showDescription.classList.toggle('roi-slide');

    if (e.target.parentNode.classList.contains('roi-open')) {
      e.target.parentNode.childNodes[1].currentTime = 0;
      e.target.parentNode.childNodes[1].play();
    } else {
      e.target.parentNode.childNodes[1].pause();
      e.target.parentNode.childNodes[1].currentTime = 0;
    }
  };

  const resetFeatures = () => {
    const resetWidths = document.getElementById('roi-dynamic-carousel') as any;
    resetWidths.classList.toggle('roi-default');
  };

  return (
    <div id="roi-dynamic-carousel" className="" onMouseEnter={resetFeatures} onMouseLeave={resetFeatures}>
      <div id="roi-slide-track" className="-z-1 relative">
        {features.map((item: any, index: number) => {
          return (
            <div
              id={`roi-slide-${index}`}
              key={index}
              className="roi-item rounded-md h-64 relative overflow-hidden inline-block px-4 pt-10 pb-56 mx-px transition-all duration-100 ">
              <div
                className="absolute z-10 top-0 left-0 w-full h-full bg-transparent cursor-pointer "
                onMouseOver={React.useCallback(debounce(activateFeature, 100), [])}
                onMouseOut={activateFeature}
                onClick={activateFeature}
              />
              <VideoPlayer videoSrc={item.background} />
              <div className="absolute top-0 pt-3">{item.icon}</div>
              <div className="roi-slider absolute bottom-0 left-0 transition-all duration-150 p-5">
                <h3
                  className="text-white font-skyBlack text-2xl transition-all duration-150 leading-6"
                  dangerouslySetInnerHTML={{ __html: item.headline }}
                />
                <p
                  className="text-white font-skyBook text-sm mt-0 leading-4 caption"
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { ArrowRoiCarousel };
