import React, { useEffect, useState } from 'react';
import { AdditionalServices } from '../../../components/additional-services/AdditionalServices';
import { Router } from '../../../components/router/Router';
import { Voice } from '../../../components/voice/Voice';
import { WifiBoosterPackageCanBuy } from '../../../components/booster/wifiBooster.package.can.buy';
import { useBroadbandSwapProduct, productSkuIds, useGetWifiGuyRecommendations, useBBname } from '@sky-tv-group/shared';
import { konakartService } from '../../../services';

const InitiatedContainer = () => {
  const { currentBroadband } = useBroadbandSwapProduct(konakartService);
  const bbName = useBBname();
  const [specialToggleLabel, setSpecialToggleLabel] = useState(`${bbName.broadbandLightningFastWiFiBoost.label} Plan`);

  const { wifiGuyRecommendedSku } = useGetWifiGuyRecommendations();
  const isBoosterrecommended = wifiGuyRecommendedSku === productSkuIds.broadbandLightningFastWiFiBoost.primary;

  const { toggleMeshWithProductSwap, swapToBoostPlan, meshAdded, meshDevice } = useBroadbandSwapProduct(
    konakartService
  );

  useEffect(() => {
    if (currentBroadband) {
      const toggleLabel = currentBroadband.name.includes(bbName.broadbandLightningFastWiFi.label)
        ? bbName.broadbandLightningFastWiFiBoost.label + ' Plan'
        : currentBroadband.name.includes(bbName.broadbandWifi100.label)
        ? bbName.broadbandWifi100Boost.label + ' Plan'
        : bbName.broadbandStarterWiFiBoost.label + ' Plan';

      setSpecialToggleLabel(toggleLabel);
    }
  }, [currentBroadband, bbName]);

  useEffect(() => {
    if (meshAdded) {
      swapToBoostPlan(true);
    }
    // eslint-disable-next-line
  }, [meshAdded]);

  if (!meshDevice) return null;

  return (
    <>
      <Router />
      <WifiBoosterPackageCanBuy
        data={meshDevice}
        toggle={toggleMeshWithProductSwap}
        showZeroAsFree={false}
        toggleLabel={specialToggleLabel}
        recommended={isBoosterrecommended}
      />
      <Voice />
      <AdditionalServices />
    </>
  );
};

export { InitiatedContainer };
