import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { routes } from '../../screens/BuildMyPlan/routes';
import { ELIGIBILITY_ACQUISITION_TYPE, useboxesStore, useEligibilityOffers } from '@sky-tv-group/shared';
import {
  useToastContainer,
  useHandleRemove,
  useProductStore,
  T_Product,
  useProductInCart,
  productSkuIds,
} from '@sky-tv-group/shared';
import { konakartService } from '../../services';

export let ButtonArea = () => {
  let boxes = useboxesStore(s => s.boxes);
  const [toastid, settoastid] = useState('');
  const handleRemove = useHandleRemove(konakartService);
  const { productsInCart } = useProductInCart();

  const skyMusic: T_Product = useProductStore(s => {
    return s.products.find(p => p.sku === '250');
  })!;

  const { addToast, removeToast } = useToastContainer();
  const musicInCart = productsInCart?.find(p => p.product.sku === '250');

  const hasPod = boxes.some(b => b.products.some(p => p.sku === productSkuIds.skyPod.primary));
  const hasArrowBox = boxes.some(b => b.products.some(p => p.sku === productSkuIds.arrowBox.primary));
  const hasskyBox = boxes.some(b =>
    b.products.some(p => p.sku === (productSkuIds.skyBoxCharge.primary || productSkuIds.paceBox500GB.primary))
  );
  const [hasArrowBoxOnClick, setArrowBoxOnClick] = useState(hasArrowBox);
  const [hasPodOnClick, setPodOnClick] = useState(hasPod);

  const { acquisitionType } = useEligibilityOffers();
  const _routes = routes.filter(r =>
    acquisitionType && acquisitionType === ELIGIBILITY_ACQUISITION_TYPE.VTV_Standalone ? r.path !== '/broadband' : true
  );
  const [isskyMusicFlag, setskyMusicFlag] = useState(false);

  let nextLabel = '';
  const location = useLocation();
  const history = useHistory();
  const cur = _routes.findIndex(r => location.pathname.includes(r.path));
  const nextRoute = _routes[cur + 1];
  const prevRoute = _routes[cur - 1];
  const canBack = cur > 0;
  let canNext = true;

  useEffect(() => {
    if (toastid) {
      if (
        hasskyBox ||
        nextRoute.title !== ('Broadband' || 'Your Details') ||
        hasPod !== hasPodOnClick ||
        hasArrowBox !== hasArrowBoxOnClick ||
        !musicInCart
      ) {
        removeToast(toastid);
        settoastid('');
      }
    }
  }, [hasskyBox, hasArrowBox, hasPod, nextRoute.title, musicInCart]);

  useEffect(() => {
    if (
      (nextRoute.title === 'Broadband' || nextRoute.title === 'Your Details') &&
      musicInCart &&
      (hasPod || hasArrowBox) &&
      !hasskyBox
    ) {
      setskyMusicFlag(true);
    } else {
      setskyMusicFlag(false);
    }
  }, [nextRoute.title, musicInCart, productsInCart, boxes]);

  const handleClick = async () => {
    setArrowBoxOnClick(hasArrowBox);
    setPodOnClick(hasPod);
    if (!toastid && musicInCart) {
      let name = `${hasPod && hasArrowBox ? 'Both the ' : 'The'}${hasArrowBox ? ' new Sky Box' : ''}${
        hasPod && hasArrowBox ? ' and ' : ''
      }${hasPod ? ' Sky Pod' : ''}${hasPod && hasArrowBox ? ' do' : ' does'}`;
      const toastID = addToast({
        title: `${skyMusic.name} channels`,
        message: ` ${name}  not support ${skyMusic.name} channels, however, there are music apps available (e.g. Spotify) on the Apps page. `,
        type: 'error',
        onClose: async () => {
          await handleRemove(skyMusic);
          settoastid('');
        },
        closeText: `Remove ${skyMusic.name} from cart`,
      });
      settoastid(toastID);
    }
  };

  const next = () => {
    let path = '/' + location.pathname.split('/')[1] + nextRoute.path;
    history.push(path);
  };
  const back = () => {
    let path = '/' + location.pathname.split('/')[1] + prevRoute.path;
    history.push(path);
  };

  const chkoutNext = () => {
    history.push('/build-my-plan/checkout');
  };

  //You can use this effect to disable enable Back and Next button based on business rules on each step
  // eslint-disable-next-line
  if (nextRoute && nextRoute.title == _routes[3].title) {
    nextLabel = 'Next: Checkout';
  } else if (nextRoute && nextRoute.title == _routes[1].title) {
    nextLabel = 'Next: Select Device';
  } else if (nextRoute && nextRoute.title == _routes[2].title) {
    nextLabel = 'Next: Add Broadband';
  } else if (nextRoute) {
    nextLabel = nextRoute.title;
  }
  // if no boxes selected, disable next button
  if (_routes[cur]?.path === routes[1].path && boxes.length === 0) {
    canNext = false;
  } else {
    canNext = true;
  }

  return (
    <div
      className={`flex flex-col lg:shadow-md md:shadow-md lg:rounded-none md:rounded-none items-center lg:flex-col xl:flex-col justify-between sky-bg-gray-light py-4 px-4 rounded-t-xl sticky-custom ${
        canBack ? ' lg:px-2 2xl:px-4 gap-3' : ' lg:px-1 xl:px-4'
      } `}>
      <span style={{ width: '100%' }} className="flex flex-row items-center justify-center">
        {nextRoute && (
          <button
            disabled={!canNext}
            onClick={isskyMusicFlag ? handleClick : next}
            className={
              (canNext ? 'sky-button--primary-pure' : 'sky-button--primary-disabled') +
              ' lg:w-full sky-button mx-0 md:px-0 2xl:px-6 w-full sm:w-auto md:w-full'
            }>
            {nextLabel}
          </button>
        )}
      </span>
      {nextRoute && nextRoute.title == _routes[2].title && (
        <button
          disabled={!canNext}
          onClick={isskyMusicFlag ? handleClick : chkoutNext}
          className={
            (canNext ? 'sky-button--primary-pure' : 'sky-button--primary-disabled') +
            ' lg:w-full sky-button mx-0 md:px-0 2xl:px-6 w-full sm:w-auto md:w-full'
          }>
          {'Or, Checkout Now'}
        </button>
      )}
    </div>
  );
};
