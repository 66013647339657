import React, { useState } from 'react';
import { caret } from '@sky-tv-group/components';

export const Important = () => {
  const [showWholeMessage, setShowWholeMessage] = useState(false);
  const toggleShowWholeMessage = () => {
    setShowWholeMessage(!showWholeMessage);
  };

  const messagePreview = 'Fibre services including home phones do not work in a power cut.  ';
  const restOfTheMessage =
    'During a power cut, your home phone and devices like cordless phones, medical alarms, etc may not work even with a battery and you will not be able to make calls including to 111 emergency services. You will need another way to make calls and contact 111 emergency services such as a charged mobile device or power back-up device.';

  return (
    <div className="md:container mx-auto pt-4">
      <div className="sky-text-midnight mx-3 md:mx-0" onClick={toggleShowWholeMessage}>
        <div className="cursor-pointer rounded-lg flex border-2 w-full p-4">
          <div className="w-11/12 p-3 flex items-center text-15px">
            <div>
              <span className="sky-text-red font-bold">IMPORTANT: </span>
              <span>{!showWholeMessage ? messagePreview : messagePreview + restOfTheMessage}</span>
            </div>
          </div>
          <div className="w-1/12 py-3 md:p-3 text-15px">
            <span
              className={`w-8 h-8 flex justify-center items-center  ${
                showWholeMessage ? 'transform -rotate-180 -translate-y-1/2' : ''
              }`}>
              {caret}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
