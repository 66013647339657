import * as React from 'react';

interface VideoPlayerProps {
  videoSrc: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoSrc }) => {
  return (
    <video width="345" height="250" muted playsInline className="roi-video absolute top-0 left-0">
      {/* <source src={"https://static.sky.co.nz/sky/roi/arrow-roi-carousel/" + videoSrc + ".webm"} type="video/webm" /> */}
      {/* <source src={'https://static.sky.co.nz/sky/roi/arrow-roi-carousel/' + videoSrc + '.mp4'} type="video/mp4" /> */}
      <source src={'https://static.sky.co.nz/sky/roi/arrow-roi-carousel/' + videoSrc + '.mp4#t=0.1'} type="video/mp4" />
    </video>
  );
};

export default VideoPlayer;
