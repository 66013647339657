import React from 'react';

import {
  CarouselContainer,
  IcedPackageCard,
  ICEPackageCardSingle,
  Login,
  NextArrow,
  OneMonthOnUs,
  PackedCarouselContainer,
  PrevArrow,
  UpcomingOffer,
} from '@sky-tv-group/components';

import { ACCOUNT_URL } from '../../config';
import { configService, konakartService } from '../../services';

import {
  useCartContainer,
  CouponTypeValue,
  useFreeProductStore,
  T_Product,
  useSplitIO,
  SPLITIO_KEY,
  useJSONConfig,
  useProductWithCouponRemoval,
  IPopUpContentConfig,
  ELIGIBILITY_ACQUISITION_TYPE,
  useEligibilityOffers,
  useToastContainer,
  useHandleRemove,
  productSkuIds,
  useCouponStore,
  couponCodes,
} from '@sky-tv-group/shared';
import { useAcquisitionJourneyState } from '../../provider/AcquisitionJourney';
import { VTVStandaloneHero } from '../../components/VTVStandaloneOrderInfo/VTVStandaloneHero';
import { VTVStandalonePreOrderInfo } from '../../components/VTVStandaloneOrderInfo/VTVStandalonePreOrderInfo';
import './buildplan.css';
import { useRef, useState } from 'react';
import { BreadcrumbContainer } from '../../components/BreadcrumbContainer';
export let TVPackages = () => {
  const config = useJSONConfig(configService);
  const { progressBar } = useAcquisitionJourneyState();
  const { acquisitionType } = useEligibilityOffers();
  const { toggleProduct, packageUpgrades, starter, specials, hindi } = useCartContainer(
    konakartService,
    CouponTypeValue.Acquisition
  );
  const { addToast } = useToastContainer();
  const handleRemove = useHandleRemove(konakartService);
  const [dartPreorderEnabled] = useSplitIO(SPLITIO_KEY.SKYWEB_VTV_DART_PREORDER);

  const premiumPackageContainerRef = useRef(null);
  const tvPackageContainerRef = useRef(null);
  const [currentPremiumPackageActiveSlide, setCurrentPremiumPackageActiveSlide] = useState(0);
  const [currentTvPackageActiveSlide, setCurrentTvPackageActiveSlide] = useState(0);

  // Sort by rating.
  let sortedAddMoreChannels = packageUpgrades.sort(
    ({ rating: previousRating }, { rating: currentRating }) => currentRating - previousRating
  );
  // Sort by rating.
  const sortedPackages = specials
    .filter(product => product?.sku !== productSkuIds.soho.primary)
    .sort(({ rating: previousRating }, { rating: currentRating }) => currentRating - previousRating)
    .concat(hindi);

  const freeProduct = useFreeProductStore(s => s.freeProduct);

  const [acqFusionBoxHide] = useSplitIO(SPLITIO_KEY.SKYWEB_ACQ_HIDE_MNTH_ONEOF_CHRGE);
  /** This is for SoHo handling. */
  const { handleRemovalOfT_ProductWithToast } = useProductWithCouponRemoval(konakartService);
  const toggleWithToast = async (product: T_Product) => {
    await handleRemovalOfT_ProductWithToast(product, toggleProduct, true);
  };

  const [skySportPriceChangePopUpEnable] = useSplitIO(SPLITIO_KEY.SKYWEB_SKY_PRICE_CHANGE_POPUP);
  let [showOneMonthOnUs] = useSplitIO(SPLITIO_KEY.SKYWEB_ONE_MONTH_ON_US);
  let [show25offer] = useSplitIO(SPLITIO_KEY.SKYWEB_25_OFF_CAMPAIGN);

  const skySportsPriceChangeResponse: IPopUpContentConfig[] = config?.popUps?.priceChanges?.content;
  const skySportsPriceChangeData = skySportPriceChangePopUpEnable ? skySportsPriceChangeResponse : undefined;
  const isVTVStandalone = acquisitionType && acquisitionType === ELIGIBILITY_ACQUISITION_TYPE.VTV_Standalone;

  const coupons = useCouponStore(s => s.coupons);
  const hasOneMonthOnUsWithSport = coupons.some(c => c.couponCode === couponCodes.oneMonthOnUsWithSport);

  const handleToggle = async (isInCart: boolean, product: T_Product) => {
    if (isInCart) {
      await handleRemove(product);
    } else {
      await handleRemovalOfT_ProductWithToast(product, toggleProduct, true);
      if (skySportsPriceChangeData) {
        Object.entries(skySportsPriceChangeData)?.map(([key, popUp]) => {
          if (popUp.sku === product.sku) {
            addToast({
              title: popUp?.title,
              message: `${popUp?.bodyCopy} <a href="${popUp?.link}" target="_blank" class="text-blue-light underline">Find out more here</a>`,
              type: 'offer',
            });
          }
        });
      }
    }
  };
  const handleTogglech = async (isInCart: boolean, product: T_Product) => {
    if (isInCart) {
      await handleRemove(product);
    } else {
      await handleRemovalOfT_ProductWithToast(product, toggleProduct, true);
    }
  };

  const returnIcedPackageCardSingleComponent = (product: T_Product) => {
    let removable = product?.id !== freeProduct?.productId && product.id !== starter?.id;
    return (
      product && (
        <>
          {/* Desktop. */}
          <div className="hidden md:block">
            <ICEPackageCardSingle
              kkService={konakartService}
              imageBackgroundClassName="bg-white"
              imageClassName="opacity-1 object-cover"
              key={product?.id}
              product={product}
              toggleProduct={toggleProduct}
              removable={removable}
              showVideo={false}
              skyPriceChangePopUp={skySportsPriceChangeData}
              handleToggle={handleToggle}
              hideContract={isVTVStandalone && product.id === starter?.id ? true : false}
            />
          </div>
          {/* // Mobile. */}
          <div className="block md:hidden">
            <IcedPackageCard
              kkService={konakartService}
              imageBackgroundClassName="bg-white"
              imageClassName="opacity-1 object-cover"
              key={product?.id}
              product={product}
              toggleProduct={toggleProduct}
              removable={removable}
              showVideo={false}
              skyPriceChangePopUp={skySportsPriceChangeData}
              handleToggle={handleToggle}
              hideContract={isVTVStandalone && product.id === starter?.id ? true : false}
            />
          </div>
        </>
      )
    );
  };

  const slickSettings = {
    adaptiveHeight: true,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    customClassName: 'more-choice-for-you-section-carousel more-choice-for-you-section-carousel-slick-dots mb-sky-lg',
  };

  return (
    <div>
      <BreadcrumbContainer progressBarStatus={false} />
      <div className="container m-auto" data-testid="tv-packages">
        <div className="flex flex-col justify-start items-start py-10 lg:px-16 md:w-100">
          <Login loginRedirectUrl={`${ACCOUNT_URL}packages`} />
        </div>
        <div className="lg:px-16 md:w-100 md:w-full lg:ml-1">
          {isVTVStandalone && (
            <>
              <VTVStandaloneHero dartPreorderEnabled={!!dartPreorderEnabled} />
              {dartPreorderEnabled && <VTVStandalonePreOrderInfo />}
            </>
          )}
          {show25offer && !isVTVStandalone && (
            <div className="mt-8 mx-4 md:m-0">
              <UpcomingOffer className="flex w-325 md:w-920" isUpgradeJourney={false} bgColor={'#fff'} />
            </div>
          )}
          <div className="max-w-80% mx-auto md:max-w-none md:mx-0 text-center md:text-left">
            {/* Starter header. */}
            <h2 className={'text-navy mb-8 mt-14'}>
              <span className="sky-h3-black md:sky-h4-black">Already</span>
              <span className="sky-h3-reg md:sky-h4-reg"> included in your subscription: </span>
            </h2>

            {/* Starter. */}
            {starter && returnIcedPackageCardSingleComponent(starter)}

            <h2 className="text-navy mb-8 mt-5 text-center md:text-left">
              <span className="sky-h3-black md:sky-h4-black">Add</span>
              <span className="sky-h3-reg md:sky-h4-reg"> TV Packages: </span>
            </h2>

            {/**web-tablet-mobile */}
            <div className="w-80% xl:block md:block lg:block">
              <CarouselContainer
                {...{
                  ...slickSettings,
                  slidesToShow: 3,
                  dots: false,
                  arrows: true,
                  speed: 250,
                  customPaging: (i: any) =>
                    i === currentTvPackageActiveSlide ? (
                      <div className="h-4 w-4 flex items-center justify-center relative" style={{ top: '7.5px' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                          <circle cx="11" cy="10.9907" r="11" fill="#0057FF" />
                        </svg>{' '}
                      </div>
                    ) : (
                      <div className="h-4 w-4 flex items-center justify-center">
                        {' '}
                        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="8" viewBox="0 0 7 8" fill="none">
                          <circle cx="3.5" cy="3.99072" r="3.5" fill="#C5C5C5" />
                        </svg>
                      </div>
                    ),
                  afterChange: (currentSlide: any) => setCurrentTvPackageActiveSlide(currentSlide),
                  prevArrow: (
                    <div>
                      <PrevArrow
                        className={`  opacity-50 ${
                          currentTvPackageActiveSlide === 0 ? 'opacity-50' : 'opacity-100'
                        }  relative -left-20px`}
                      />
                    </div>
                  ),
                  nextArrow: (
                    <div>
                      <NextArrow
                        className={` ${
                          currentTvPackageActiveSlide === sortedAddMoreChannels.length - 3
                            ? 'opacity-50'
                            : 'opacity-100'
                        }  relative right-12px`}
                      />
                    </div>
                  ),

                  responsiveBlock: [
                    {
                      breakpoint: 750,
                      settings: {
                        dots: true,
                        arrows: false,
                        infinite: false,
                        slidesToShow: 1,
                        swipeToSlide: true,
                        centerMode: true,
                        centerPadding: '20px',
                        customPaging: (i: any) =>
                          i === currentTvPackageActiveSlide ? (
                            <div className="h-4 w-4 flex items-center justify-center relative" style={{ top: '7.5px' }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none">
                                <circle cx="11" cy="10.9907" r="11" fill="#0057FF" />
                              </svg>{' '}
                            </div>
                          ) : (
                            <div className="h-4 w-4 flex items-center justify-center">
                              {' '}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="7"
                                height="8"
                                viewBox="0 0 7 8"
                                fill="none">
                                <circle cx="3.5" cy="3.99072" r="3.5" fill="#C5C5C5" />
                              </svg>
                            </div>
                          ),
                        afterChange: (currentSlide: any) => setCurrentTvPackageActiveSlide(currentSlide),
                      },
                    },
                    {
                      breakpoint: 1026,
                      settings: {
                        dots: true,
                        arrows: true,
                        infinite: false,
                        swipeToSlide: true,
                        slidesToShow: 2,
                        customPaging: (i: any) =>
                          i === currentTvPackageActiveSlide ? (
                            <div className="h-4 w-4 flex items-center justify-center relative" style={{ top: '7.5px' }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none">
                                <circle cx="11" cy="10.9907" r="11" fill="#0057FF" />
                              </svg>{' '}
                            </div>
                          ) : (
                            <div className="h-4 w-4 flex items-center justify-center">
                              {' '}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="7"
                                height="8"
                                viewBox="0 0 7 8"
                                fill="none">
                                <circle cx="3.5" cy="3.99072" r="3.5" fill="#C5C5C5" />
                              </svg>
                            </div>
                          ),
                        afterChange: (currentSlide: any) => setCurrentTvPackageActiveSlide(currentSlide),
                        prevArrow: (
                          <div>
                            <PrevArrow
                              className={`  opacity-50 ${
                                currentTvPackageActiveSlide === 0 ? 'opacity-50' : 'opacity-100'
                              }  relative -left-20px`}
                            />
                          </div>
                        ),
                        nextArrow: (
                          <div>
                            <NextArrow
                              className={` ${
                                currentTvPackageActiveSlide === sortedAddMoreChannels.length - 2
                                  ? 'opacity-50'
                                  : 'opacity-100'
                              }  relative right-12px`}
                            />
                          </div>
                        ),
                      },
                    },
                  ],
                }}>
                {(sortedAddMoreChannels ?? []).map(product => {
                  let removable =
                    product?.id !== freeProduct?.productId &&
                    (!hasOneMonthOnUsWithSport || product.sku !== productSkuIds.entertainment.primary);
                  return (
                    <div className=" w-full  px-3" key={product.id}>
                      <IcedPackageCard
                        kkService={konakartService}
                        imageBackgroundClassName="bg-white"
                        imageClassName="opacity-1 object-cover"
                        key={product.id}
                        product={product}
                        toggleProduct={toggleWithToast}
                        removable={removable}
                        showVideo={true}
                        skyPriceChangePopUp={skySportsPriceChangeData}
                        handleToggle={handleToggle}
                      />
                    </div>
                  );
                })}
              </CarouselContainer>
            </div>

            <h2 className={'text-navy mb-8 mt-8 text-center md:text-left'}>
              <span className="sky-h3-black md:sky-h4-black">Add</span>
              <span className="sky-h3-reg md:sky-h4-reg"> Premium Channels: </span>
            </h2>

            {/*---mobile---*/}
            <div className="w-80%  relative">
              <CarouselContainer
                {...{
                  ...slickSettings,
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  arrows: true,
                  dots: false,
                  speed: 250,
                  customPaging: (i: any) =>
                    i === currentPremiumPackageActiveSlide ? (
                      <div className="h-4 w-4 flex items-center justify-center relative" style={{ top: '7.5px' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                          <circle cx="11" cy="10.9907" r="11" fill="#0057FF" />
                        </svg>{' '}
                      </div>
                    ) : (
                      <div className="h-4 w-4 flex items-center justify-center">
                        {' '}
                        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="8" viewBox="0 0 7 8" fill="none">
                          <circle cx="3.5" cy="3.99072" r="3.5" fill="#C5C5C5" />
                        </svg>
                      </div>
                    ),
                  afterChange: (currentSlide: any) => setCurrentPremiumPackageActiveSlide(currentSlide),
                  prevArrow: (
                    <div>
                      <PrevArrow
                        className={`  opacity-50 ${
                          currentPremiumPackageActiveSlide === 0 ? 'opacity-50' : 'opacity-100'
                        }  relative -left-20px`}
                      />
                    </div>
                  ),
                  nextArrow: (
                    <div>
                      <NextArrow
                        className={` ${
                          currentPremiumPackageActiveSlide === sortedPackages.length - (acqFusionBoxHide ? 4 : 3)
                            ? 'opacity-50'
                            : 'opacity-100'
                        }  relative right-12px`}
                      />
                    </div>
                  ),
                  responsiveBlock: [
                    {
                      breakpoint: 750,
                      settings: {
                        dots: true,
                        arrows: false,
                        infinite: false,
                        slidesToShow: 1,
                        swipeToSlide: true,
                        centerMode: true,
                        centerPadding: '20px',
                        customPaging: (i: any) =>
                          i === currentPremiumPackageActiveSlide ? (
                            <div className="h-4 w-4 flex items-center justify-center relative" style={{ top: '7.5px' }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none">
                                <circle cx="11" cy="10.9907" r="11" fill="#0057FF" />
                              </svg>{' '}
                            </div>
                          ) : (
                            <div className="h-4 w-4 flex items-center justify-center">
                              {' '}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="7"
                                height="8"
                                viewBox="0 0 7 8"
                                fill="none">
                                <circle cx="3.5" cy="3.99072" r="3.5" fill="#C5C5C5" />
                              </svg>
                            </div>
                          ),
                        afterChange: (currentSlide: any) => setCurrentPremiumPackageActiveSlide(currentSlide),
                      },
                    },
                    {
                      breakpoint: 1026,
                      settings: {
                        dots: true,
                        arrows: true,
                        infinite: false,
                        swipeToSlide: true,
                        slidesToShow: 2,
                        customPaging: (i: any) =>
                          i === currentPremiumPackageActiveSlide ? (
                            <div className="h-4 w-4 flex items-center justify-center relative" style={{ top: '7.5px' }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none">
                                <circle cx="11" cy="10.9907" r="11" fill="#0057FF" />
                              </svg>{' '}
                            </div>
                          ) : (
                            <div className="h-4 w-4 flex items-center justify-center">
                              {' '}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="7"
                                height="8"
                                viewBox="0 0 7 8"
                                fill="none">
                                <circle cx="3.5" cy="3.99072" r="3.5" fill="#C5C5C5" />
                              </svg>
                            </div>
                          ),
                        afterChange: (currentSlide: any) => setCurrentPremiumPackageActiveSlide(currentSlide),
                        prevArrow: (
                          <div>
                            <PrevArrow
                              className={`  opacity-50 ${
                                currentPremiumPackageActiveSlide === 0 ? 'opacity-50' : 'opacity-100'
                              }  relative -left-20px`}
                            />
                          </div>
                        ),
                        nextArrow: (
                          <div>
                            <NextArrow
                              className={` ${
                                currentPremiumPackageActiveSlide === sortedPackages.length - (acqFusionBoxHide ? 3 : 2)
                                  ? 'opacity-50'
                                  : 'opacity-100'
                              }  relative right-12px`}
                            />
                          </div>
                        ),
                      },
                    },
                  ],
                }}>
                {(sortedPackages ?? [])
                  .filter(product =>
                    product?.sku === productSkuIds.skyDigitalMusic.primary && acqFusionBoxHide ? false : true
                  )
                  .map(product => {
                    let removable =
                      product.id !== freeProduct?.productId &&
                      (!hasOneMonthOnUsWithSport || product.sku !== productSkuIds.soho.primary);
                    return (
                      <div className=" w-full  px-3" key={product.id}>
                        <IcedPackageCard
                          kkService={konakartService}
                          imageBackgroundClassName="bg-white"
                          imageClassName="opacity-1 object-cover"
                          key={product.id}
                          product={product}
                          toggleProduct={toggleProduct}
                          removable={removable}
                          handleToggle={handleTogglech}
                        />
                      </div>
                    );
                  })}
              </CarouselContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
