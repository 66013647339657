import React, { useState } from 'react';
import { newSkyBoxwithoutRecordingInfo, newSkyBoxwithRecordingInfo, SkyPodwithRecording } from './textData';
import {
  UpdatesIcon,
  InternetSymbol,
  HelpIcon,
  AudioIcon,
  NotificationIcon,
  RecordIcon,
  TVNZIcon,
  ArrowDown,
  ArrowUp,
} from '../icon';
import { skyPodInfoURL, newSkyBoxInfoURL } from '@sky-tv-group/shared';
import { SourceJourney } from '../SkyBoxPrimary';
export interface SkyBoxDescriptionProps {
  isSkyPodSelected?: boolean;
  boxHasRecording?: boolean;
  isArrowSelected?: boolean;
  source?: string;
}

export const SkyBoxDescription = ({
  isSkyPodSelected,
  boxHasRecording,
  isArrowSelected,
  source,
}: SkyBoxDescriptionProps &
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => {
  // Url for sky pod
  const skypodLink = (
    <a href={skyPodInfoURL} target="_blank" rel="noreferrer noopener" className="sky-text-daylight underline">
      here
    </a>
  );
  // Url for new sky box
  const newskyboxLink = (
    <a href={newSkyBoxInfoURL} target="_blank" rel="noreferrer noopener" className="sky-text-daylight underline">
      here
    </a>
  );

  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);

  return (
    <>
      <div className="hidden md:flex ">
        <div className="flex flex-row gap-6">
          <div className="flex flex-col  gap-2 h-auto w-1/2">
            <div className="flex flex-col ">
              <div className="flex flex-row">
                <div className="mx-1">
                  <span>
                    <InternetSymbol />
                  </span>
                </div>
                <div>
                  <h2 className="sky-h6-bold sky-text-midnight">
                    {isArrowSelected
                      ? newSkyBoxwithRecordingInfo.internetInfo.title
                      : SkyPodwithRecording.internetInfo.title}
                  </h2>
                  <p className="sky-h7-reg sky-text-midnight">
                    {source === SourceJourney.WEB_ICE
                      ? isArrowSelected
                        ? newSkyBoxwithRecordingInfo.internetInfo.description +
                          'If your home lacks an internet connection or your service coverage is poor, please contact our friendly Sky crew on 09 525 5555 for alternative solutions to enjoy Sky.'
                        : SkyPodwithRecording.internetInfo.description +
                          'If your home lacks an internet connection or your service coverage is poor, please contact our friendly Sky crew on 09 525 5555 for alternative solutions to enjoy Sky.'
                      : isArrowSelected
                      ? newSkyBoxwithRecordingInfo.internetInfo.description
                      : SkyPodwithRecording.internetInfo.description}
                  </p>
                </div>
              </div>
            </div>
            {/* 2 */}
            {(isArrowSelected && boxHasRecording) || (boxHasRecording && isSkyPodSelected) ? (
              <div
                className={`flex flex-col ${isArrowSelected && source === SourceJourney.WEB_ACCOUNT ? 'hidden' : ''}`}>
                <div className="flex flex-row">
                  <div className="mx-1">
                    <span>
                      <RecordIcon />
                    </span>
                  </div>
                  <div>
                    <h2 className="sky-h6-bold sky-text-midnight font-bold">
                      {isArrowSelected && boxHasRecording
                        ? newSkyBoxwithRecordingInfo.recordingInfo.title
                        : SkyPodwithRecording.recordingInfo.title}
                    </h2>
                    <p className="sky-h7-reg sky-text-midnight ">
                      {isArrowSelected && boxHasRecording
                        ? newSkyBoxwithRecordingInfo.recordingInfo.description
                        : SkyPodwithRecording.recordingInfo.description}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className={`flex flex-col ${isArrowSelected && source === SourceJourney.WEB_ACCOUNT ? 'hidden' : ''}`}>
              <div className="flex flex-row">
                <div className="mx-1">
                  <span>
                    <UpdatesIcon />
                  </span>
                </div>
                <div>
                  <h2 className="sky-h6-bold sky-text-midnight font-bold">
                    {isArrowSelected
                      ? newSkyBoxwithoutRecordingInfo.upcomingUpdatesInfo.title
                      : SkyPodwithRecording.upcomingUpdatesInfo.title}
                  </h2>
                  <p className="sky-h7-reg sky-text-midnight">
                    {isArrowSelected
                      ? newSkyBoxwithoutRecordingInfo.upcomingUpdatesInfo.description
                      : SkyPodwithRecording.upcomingUpdatesInfo.description}
                    {isArrowSelected && newskyboxLink}
                    {isSkyPodSelected && skypodLink}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* {4} */}
          <div className="flex flex-col  gap-2 h-auto w-1/2">
            <div className="flex flex-col">
              <div className="flex flex-row">
                <div className="mx-1">
                  {isSkyPodSelected ? (
                    <span>
                      <AudioIcon />
                    </span>
                  ) : (
                    <span>
                      <NotificationIcon />
                    </span>
                  )}
                </div>
                <div>
                  <h2 className="sky-h6-bold sky-text-midnight font-bold">
                    {isSkyPodSelected
                      ? SkyPodwithRecording.digitalMusicInfo.title
                      : newSkyBoxwithRecordingInfo.remindersInfo.title}
                  </h2>
                  <p className="sky-h7-reg sky-text-midnight">
                    {isSkyPodSelected
                      ? SkyPodwithRecording.digitalMusicInfo.description
                      : newSkyBoxwithRecordingInfo.remindersInfo.description}
                  </p>
                </div>
              </div>
            </div>
            {/* {5} */}
            <div className={`flex flex-col ${isArrowSelected && source === SourceJourney.WEB_ACCOUNT ? 'hidden' : ''}`}>
              <div className="flex flex-row">
                <div className="mx-1">
                  {isSkyPodSelected ? (
                    <span>
                      <TVNZIcon />
                    </span>
                  ) : (
                    <span>
                      <AudioIcon />
                    </span>
                  )}
                </div>
                <div>
                  <h2 className="sky-h6-bold sky-text-midnight font-bold">
                    {isSkyPodSelected
                      ? SkyPodwithRecording.TVNZChannelsInfo.title
                      : newSkyBoxwithoutRecordingInfo.digitalMusicInfo.title}
                  </h2>
                  <p className="sky-h7-reg sky-text-midnight">
                    {isSkyPodSelected
                      ? SkyPodwithRecording.TVNZChannelsInfo.description
                      : newSkyBoxwithoutRecordingInfo.digitalMusicInfo.description}
                  </p>
                </div>
              </div>
            </div>
            {/* {6} */}
            <div className="flex flex-col">
              <div className="flex flex-row">
                <div className="mx-1">
                  <span>
                    <HelpIcon />
                  </span>
                </div>
                <div>
                  <h2 className="sky-h6-bold sky-text-midnight font-bold">
                    {isArrowSelected
                      ? newSkyBoxwithRecordingInfo.soundSystemInfo.title
                      : SkyPodwithRecording.soundSystemInfo.title}
                  </h2>
                  <p className="sky-h7-reg sky-text-midnight ">
                    {isArrowSelected ? (
                      <>
                        {newSkyBoxwithRecordingInfo.soundSystemInfo.description}{' '}
                        {source === SourceJourney.WEB_ACCOUNT ? (
                          <a
                            href="https://help.sky.co.nz/s/article/New-Sky-Box-FAQs"
                            className="sky-text-daylight underline">
                            https://help.sky.co.nz/s/article/New-Sky-Box-FAQs
                          </a>
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      SkyPodwithRecording.soundSystemInfo.description
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* for mobile screens  */}
      <div className="flex flex-col w-375px md:hidden lg:hidden">
        <div className="flex flex-col gap-2">
          <div className="flex flex-col">
            <div className="flex w-full justify-between">
              <div className="flex flex-row">
                <span className="mr-2">
                  <InternetSymbol />
                </span>
                <h2 className="sky-label sky-text-midnight font-bold mt-1">
                  {isArrowSelected
                    ? newSkyBoxwithRecordingInfo.internetInfo.title
                    : SkyPodwithRecording.internetInfo.title}
                </h2>
              </div>
              <div>
                <span onClick={() => setShow1(!show1)}>{show1 ? <ArrowDown /> : <ArrowUp />}</span>
              </div>
            </div>
            {show1 && (
              <p className="sky-h7-reg font-normal sky-text-midnight mt-1 ml-10">
                {source === SourceJourney.WEB_ICE
                  ? isArrowSelected
                    ? newSkyBoxwithRecordingInfo.internetInfo.description +
                      'If your home lacks an internet connection or your service coverage is poor, please contact our friendly Sky crew on 09 525 5555 for alternative solutions to enjoy Sky.'
                    : SkyPodwithRecording.internetInfo.description +
                      'If your home lacks an internet connection or your service coverage is poor, please contact our friendly Sky crew on 09 525 5555 for alternative solutions to enjoy Sky.'
                  : isArrowSelected
                  ? newSkyBoxwithRecordingInfo.internetInfo.description
                  : SkyPodwithRecording.internetInfo.description}
              </p>
            )}
          </div>
          {/* //2 */}
          {((isArrowSelected && boxHasRecording) || (boxHasRecording && isSkyPodSelected)) && (
            <div className={`flex flex-col ${isArrowSelected && source === SourceJourney.WEB_ACCOUNT ? 'hidden' : ''}`}>
              <div className="flex w-full justify-between">
                <div className="flex flex-row">
                  <span className="mr-2 ">
                    <RecordIcon />{' '}
                  </span>
                  <h2 className="sky-label sky-text-midnight font-bold mt-1">
                    {isArrowSelected && boxHasRecording
                      ? newSkyBoxwithRecordingInfo.recordingInfo.title
                      : SkyPodwithRecording.recordingInfo.title}
                  </h2>
                </div>
                <div>
                  <span onClick={() => setShow2(!show2)}>{show2 ? <ArrowDown /> : <ArrowUp />}</span>
                </div>
              </div>
              {show2 && (
                <p className="sky-h7-reg font-normal sky-text-midnight mt-1 ml-10">
                  {isArrowSelected && boxHasRecording
                    ? newSkyBoxwithRecordingInfo.recordingInfo.description
                    : SkyPodwithRecording.recordingInfo.description}
                </p>
              )}
            </div>
          )}
          {/* //3 */}

          <div className={`flex flex-col ${isArrowSelected && source === SourceJourney.WEB_ACCOUNT ? 'hidden' : ''}`}>
            <div className="flex w-full justify-between">
              <div className="flex flex-row">
                <span className="mr-2 mt-1">
                  <UpdatesIcon />
                </span>
                <h2 className="sky-label md-sky-label sky-text-midnight font-bold mt-1">
                  {isArrowSelected
                    ? newSkyBoxwithoutRecordingInfo.upcomingUpdatesInfo.title
                    : SkyPodwithRecording.upcomingUpdatesInfo.title}
                </h2>
              </div>
              <div>
                <span onClick={() => setShow3(!show3)}>{show3 ? <ArrowDown /> : <ArrowUp />}</span>
              </div>
            </div>
            {show3 && (
              <p className="sky-h7-reg font-normal sky-text-midnight mt-1 ml-10 ">
                {isArrowSelected
                  ? newSkyBoxwithoutRecordingInfo.upcomingUpdatesInfo.description
                  : SkyPodwithRecording.upcomingUpdatesInfo.description}
                {isArrowSelected && newskyboxLink}
                {isSkyPodSelected && skypodLink}
              </p>
            )}
          </div>

          {/* //4 */}
          <div className="flex flex-col">
            <div className="flex w-full justify-between">
              <div className="flex flex-row">
                {isArrowSelected ? (
                  <span className="mr-2 mt-1">
                    <NotificationIcon />
                  </span>
                ) : (
                  <span className="mr-2 mt-1">
                    <AudioIcon />
                  </span>
                )}
                <h2 className="sky-label sky-text-midnight font-bold mt-1">
                  {isArrowSelected
                    ? newSkyBoxwithRecordingInfo.remindersInfo.title
                    : SkyPodwithRecording.digitalMusicInfo.title}
                </h2>
              </div>
              <div>
                <span onClick={() => setShow4(!show4)}>{show4 ? <ArrowDown /> : <ArrowUp />}</span>
              </div>
            </div>
            {show4 && (
              <p className="sky-h7-reg font-normal sky-text-midnight mt-1 ml-10">
                {isArrowSelected
                  ? newSkyBoxwithRecordingInfo.remindersInfo.description
                  : SkyPodwithRecording.digitalMusicInfo.description}
              </p>
            )}
          </div>

          {/* 5 */}
          <div className={`flex flex-col ${isArrowSelected && source === SourceJourney.WEB_ACCOUNT ? 'hidden' : ''}`}>
            <div className="flex w-full justify-between">
              <div className="flex flex-row">
                {isArrowSelected ? (
                  <span className="mr-2">
                    <AudioIcon />
                  </span>
                ) : (
                  <span className="mr-2">
                    <TVNZIcon />
                  </span>
                )}
                <h2 className="sky-label sky-text-midnight font-bold mt-1">
                  {isArrowSelected
                    ? newSkyBoxwithoutRecordingInfo.digitalMusicInfo.title
                    : SkyPodwithRecording.TVNZChannelsInfo.title}
                </h2>
              </div>
              <div>
                <span onClick={() => setShow5(!show5)}>{show5 ? <ArrowDown /> : <ArrowUp />}</span>
              </div>
            </div>
            {show5 && (
              <p className="sky-h7-reg font-normal sky-text-midnight mt-1 ml-10">
                {isArrowSelected
                  ? newSkyBoxwithoutRecordingInfo.digitalMusicInfo.description
                  : SkyPodwithRecording.TVNZChannelsInfo.description}
              </p>
            )}
          </div>
          {/* 6 */}
          <div className="flex flex-col">
            <div className="flex w-full justify-between">
              <div className="flex flex-row">
                <span className="mr-2 mt-1">
                  <HelpIcon />
                </span>
                <h2 className="sky-label sky-text-midnight font-bold mt-1">
                  {isArrowSelected
                    ? newSkyBoxwithRecordingInfo.soundSystemInfo.title
                    : SkyPodwithRecording.soundSystemInfo.title}
                </h2>
              </div>
              <div>
                <span onClick={() => setShow6(!show6)}>{show6 ? <ArrowDown /> : <ArrowUp />}</span>
              </div>
            </div>
            {show6 && (
              <div>
                <p className="sky-h7-reg font-normal sky-text-midnight mt-1 ml-10 ">
                  {isArrowSelected ? (
                    <>
                      {newSkyBoxwithRecordingInfo.soundSystemInfo.description}{' '}
                      {source === SourceJourney.WEB_ACCOUNT ? (
                        <a
                          href="https://help.sky.co.nz/s/article/New-Sky-Box-FAQs"
                          className="sky-text-daylight underline">
                          https://help.sky.co.nz/s/article/New-Sky-Box-FAQs
                        </a>
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    SkyPodwithRecording.soundSystemInfo.description
                  )}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
