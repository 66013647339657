/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { T_Product, T_PlanSelector, productSkuIds, useToastContainer } from '@sky-tv-group/shared';

// components
import { BlockLoader } from '@sky-tv-group/components';
import { isMobile } from 'react-device-detect';
import { useChoosePlan } from '../../hooks/useChoosePlan';
import { Plan } from './Plan';
import { isPlanSelectedBasedOnSku } from '../../helper';

interface PlanSelectorProps {
  // hasMeshDevice: boolean;
  // broadbandProducts: T_Product[];
  isStarterInCart: boolean | undefined;
  onSelectChange: (product: T_Product) => void;
  // isUserAllowedToContinue?: boolean;
  canPurchaseBroadband?: boolean;
  planArr: T_PlanSelector[];
}

const CardPlanSelector = ({ onSelectChange, isStarterInCart, canPurchaseBroadband, planArr }: PlanSelectorProps) => {
  const { lfwProduct, wifi100Product, broadbandProductInCart, fibreStarterProduct } = useChoosePlan();
  const { addToast } = useToastContainer();

  // Select broadband product based on if user has booster/mesh in their cart
  const handleCardSelect = (sku: string) => {
    let selectedBBProduct = null;

    if (
      sku === productSkuIds.broadbandLightningFastWiFiBoost.primary ||
      sku === productSkuIds.broadbandLightningFastWiFi.primary
    ) {
      selectedBBProduct = lfwProduct;
    } else if (sku === productSkuIds.broadbandWifi100Boost.primary || sku === productSkuIds.broadbandWifi100.primary) {
      selectedBBProduct = wifi100Product;
    } else {
      selectedBBProduct = fibreStarterProduct;
    }

    if (!selectedBBProduct) {
      addToast({
        title: 'Alert',
        message: 'There was an issue making this change, please try again',
        type: 'error',
      });

      return;
    }

    onSelectChange(selectedBBProduct);
  };

  if (!planArr) return <BlockLoader />;

  // Workaround Show column in portrait and row in landscape/desktop :(
  const flexClass = isMobile && window.innerHeight > window.innerWidth ? 'flex-col items-center' : 'flex-row';
  // Nasty iPad workaround
  // const widthClass = isMobile && !isBrowser && isTablet && window.innerHeight > window.innerWidth ? 'w-3/5' : 'w-full';
  // Ever nastier workaround to change array order for all portrait modes
  // const portraitPlanArr = [planArr[1], planArr[2], planArr[0], planArr[3]];
  // Change order if its portrait or landscape
  // const plans = window.innerHeight > window.innerWidth ? portraitPlanArr : planArr;

  return (
    <div className={`container max-w-80% md:max-w-none w-full mx-auto flex justify-center ${flexClass} flex-wrap `}>
      {planArr.map((plan, index) => {
        if (plan.bundle) {
          // don't show bundle plans when its a sky starter customer
          return null;
        }

        return (
          <div
            className="w-full md:w-1/2 xl:w-full lg:px-1 sm:px-2 px-2 py-4"
            data-testid={`plan-arr-${plan.index}`}
            key={index}>
            <Plan
              key={index}
              plan={plan}
              onPlanSelect={handleCardSelect}
              isStarterInCart={isStarterInCart}
              canPurchaseBroadband={canPurchaseBroadband}
              isPlanSelected={isPlanSelectedBasedOnSku(plan.product?.sku!, broadbandProductInCart)}
            />
          </div>
        );
      })}
    </div>
  );
};

export { CardPlanSelector };
